import * as React from 'react';
import Text from '../../../shared/Text';
import { LoginPageType } from '../../constants/types';
import { getLoginPageType } from '../../utils/login-page-type';
import { PAGE_URL_CPG } from '../../constants/urls';

const RedirectLinkCrashPlan = () => {
  const loginPageType = getLoginPageType();

  if (loginPageType === LoginPageType.CRASHPLAN || !window.__CPG_URL) {
    return null;
  }

  return (
    <p className="c42-redirect-text" data-test="redirect-cpg">
      <a href={PAGE_URL_CPG}>{Text.get('redirect_to_CrashPlan_login_text')}</a>
    </p>
  );
};

export default RedirectLinkCrashPlan;
