import React from 'react';

const CrashPlanWithText = () => {
  return (
    <svg
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    id="Layer_1"
    x={0}
    y={0}
    style={{
      enableBackground: "new 0 0 360 47.96",
    }}
    viewBox="0 0 360 47.96"
    height="28"
    width="180"
  >
    <style>{".st1{fill:#0c2d4d}"}</style>
    <g id="Group_65120" transform="translate(-244 -435.073)">
      <path
        id="Path_58068"
        d="M594.58 459.63h-.85v-5.85h-1.87v-.79h4.59v.79h-1.87v5.85zm8.57-5.49-2.26 5.49h-.24l-2.26-5.49v5.49h-.85v-6.64h1.3l1.93 4.83 1.93-4.83h1.3v6.64h-.84v-5.49z"
        style={{
          fill: "#00223d",
        }}
      />
      <g id="Group_65118" transform="translate(805.967)">
        <path
          id="Path_70177"
          d="M-479.32 462.4c0-12.23 9.27-20.54 20.83-20.54 9.08 0 14.14 4.78 16.91 9.75l-7.26 3.53c-1.89-3.58-5.6-5.82-9.65-5.83-6.88 0-12.13 5.54-12.13 12.99s5.25 12.99 12.13 12.99c4.03.03 7.75-2.18 9.65-5.73l7.26 3.54c-2.87 4.97-7.83 9.75-16.91 9.75-11.56.09-20.83-8.22-20.83-20.45"
          className="st1"
        />
        <path
          id="Path_70178"
          d="M-437.85 453.51h7.55v3.92c2.24-2.79 5.59-4.47 9.17-4.59v7.36c-.69-.12-1.4-.19-2.1-.19-2.48 0-5.83 1.43-7.07 3.25v19.01h-7.55v-28.76z"
          className="st1"
        />
        <path
          id="Path_70179"
          d="M-400.4 479.21c-2.01 2.29-5.35 3.73-9.08 3.73-4.59 0-10.03-3.06-10.03-9.55 0-6.69 5.45-9.17 10.03-9.17 3.82 0 7.17 1.15 9.08 3.54v-4.01c0-2.87-2.48-4.78-6.31-4.78a12.56 12.56 0 0 0-8.41 3.34l-2.87-5.06a19.04 19.04 0 0 1 12.52-4.49c6.59 0 12.52 2.58 12.52 10.89v18.54h-7.55l.1-2.98zm0-7.45c-1.24-1.72-3.63-2.48-6.11-2.48-2.96 0-5.45 1.53-5.45 4.3 0 2.68 2.48 4.2 5.45 4.2 2.48 0 4.87-.86 6.11-2.48v-3.54z"
          className="st1"
        />
        <path
          id="Path_70180"
          d="M-385.69 473.19c2.78 2.38 6.28 3.76 9.94 3.92 3.25 0 4.87-1.15 4.87-2.87 0-2.01-2.48-2.77-5.73-3.34-4.97-.96-11.27-2.1-11.27-8.98 0-4.78 4.2-9.08 11.75-9.08 4.19-.05 8.26 1.34 11.56 3.92l-3.06 5.16c-1.72-1.82-4.97-3.34-8.5-3.34-2.77 0-4.49 1.05-4.49 2.68 0 1.82 2.29 2.48 5.45 3.06 4.97.96 11.56 2.29 11.56 9.46 0 5.25-4.49 9.27-12.42 9.27-4.97 0-9.84-1.72-12.9-4.49l3.24-5.37z"
          className="st1"
        />
        <path
          id="Path_70181"
          d="M-339.83 464.69c0-4.01-2.1-5.25-5.35-5.25-2.6.04-5.03 1.27-6.59 3.34v19.3h-7.55v-39.55h7.55v14.71c2.56-2.89 6.26-4.53 10.13-4.49 6.31 0 9.36 3.44 9.36 9.08v20.45h-7.55v-17.59z"
          className="st1"
        />
        <path
          id="Path_70182"
          d="M-325.78 442.53h18.54c8.6 0 13.38 5.83 13.38 12.8 0 6.88-4.78 12.71-13.38 12.71h-10.13v14.24h-8.5l.09-39.75zm17.48 7.26h-8.98v10.99h8.98c3.34 0 5.83-2.1 5.83-5.45s-2.49-5.54-5.83-5.54"
          className="st1"
        />
        <path
          id="Rectangle_141397"
          d="M-289.95 442.53h7.55v39.75h-7.55z"
          className="st1"
        />
        <path
          id="Path_70183"
          d="M-258.9 479.21c-2.01 2.29-5.35 3.73-9.08 3.73-4.59 0-10.03-3.06-10.03-9.55 0-6.69 5.45-9.17 10.03-9.17 3.82 0 7.17 1.15 9.08 3.54v-4.01c0-2.87-2.48-4.78-6.31-4.78a12.56 12.56 0 0 0-8.41 3.34l-2.87-5.06a19.04 19.04 0 0 1 12.52-4.49c6.59 0 12.52 2.58 12.52 10.89v18.54H-259v-2.96h.1zm0-7.45c-1.24-1.72-3.63-2.48-6.11-2.48-2.96 0-5.45 1.53-5.45 4.3 0 2.68 2.48 4.2 5.45 4.2 2.48 0 4.78-.86 6.11-2.48v-3.54z"
          className="st1"
        />
        <path
          id="Path_70184"
          d="M-225.56 464.88c0-4.01-2.1-5.35-5.25-5.35-2.6.04-5.03 1.27-6.59 3.34v19.39h-7.55V453.5h7.55v3.73c2.53-2.89 6.19-4.53 10.03-4.49 6.31 0 9.36 3.54 9.36 9.17v20.35h-7.55v-17.38z"
          className="st1"
        />
        <path
          id="Path_70185"
          d="M-493.55 448.74h-5.16v34.2h-63.25v-47.87h23.5l7.36 6.88h20.83l-8.6 6.78h-14.9l-7.36-6.78h-13.95v34.2h49.59v-27.42h-5.16l8.6-6.78 8.5 6.79z"
          style={{
            fill: "#49b5e7",
          }}
        />
      </g>
    </g>
  </svg>
  );
};

export default CrashPlanWithText;
