import { connect } from 'react-redux';
import Component from '../../components/login-form/step-agent-login-error';
import StoreInterface from '../../store-interface';

const mapStateToProps = (state: StoreInterface) => {
  const authData = state.authData.toJS();
  const loginError = authData.loginError

  return {
    loginError
  };
};

export default connect(mapStateToProps)(Component);
