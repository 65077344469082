import * as React from 'react';
import Text from '../../../shared/Text';
import TextField from '@c42/ui/lib/text-field';
import { sendEmptyTextIfNull } from '../../utils/input-handlers';
import { Inputs } from '../../../shared/dataTest';

interface Props {
  username: string | null;
  notification?: string | null;
  isDisabled?: boolean;
  errorText?: string | null;
  onChangeUsername: React.FormEventHandler<any>;
  textFieldProps?: TextField;
}

export default class UsernameInput extends React.Component<Props, {}> {
  render() {
    const { errorText, username, onChangeUsername, isDisabled, notification, textFieldProps } = this.props;

    const isInputDirty = username !== null;
    const isUsernameEmpty = !username;

    const invalidInputError = isInputDirty && isUsernameEmpty ? Text.get('_validate_username_or_email') : '';

    return (
      <div data-test={Inputs.USERNAME_CONTAINER}>
        <TextField
          autoFocus
          displayErrorInLabel
          disabled={isDisabled}
          className="form-control-wrapper"
          data-test="username"
          value={username || ''}
          errorText={errorText || invalidInputError}
          autoComplete="off"
          id="username"
          floatingLabelText={Text.get('_placeholder_username_or_email')}
          onBlur={sendEmptyTextIfNull.bind(null, onChangeUsername, username)}
          onChange={onChangeUsername}
          {...textFieldProps}
        />
      </div>
    );
  }
}
