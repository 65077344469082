import React from 'react';
import SmbBrandLogoSvg from './SmbBrandLogoSvg';
import CrashPlanWithText from './CrashPlanWithText';
import { LoginPageType } from '../../constants/types';
import { getLoginPageType } from '../../utils/login-page-type';

const LogoIcon: React.StatelessComponent = () => {
  const loginPageType = getLoginPageType();
  switch (loginPageType) {
    case LoginPageType.SMB:
      return <SmbBrandLogoSvg />;
    case LoginPageType.CRASHPLAN:
      return <CrashPlanWithText />;
    default:
      return <CrashPlanWithText />;
  }
};

export default LogoIcon;
