const PropTypes = require('prop-types');
const React          = require('react');
const createReactClass = require('create-react-class');
const Classable      = require('./mixins/classable');
const Paper          = require('./paper');
const EnhancedSwitch = require('./enhanced-switch');
const _omit          = require('lodash/omit');

const Toggle = createReactClass({
  displayName: 'Toggle',
  mixins: [Classable],

  propTypes: {
    onToggle: PropTypes.func,
    toggled: PropTypes.bool,
    defaultToggled: PropTypes.bool
  },

  render: function() {
    const toggleElement = (
      <div>
        <div className="mui-toggle-track" />
        <Paper className="mui-toggle-thumb" zDepth={0}/>
      </div>
    );
    const propsCleaned = _omit(this.props, ['onToggle', 'toggled', 'defaultToggled']);
    const enhancedSwitchProps = {
      ...propsCleaned,
      ref: "enhancedSwitch",
      inputType: "checkbox",
      switchElement: toggleElement,
      className: "mui-toggle",
      iconClassName: "mui-toggle-icon",
      onSwitch: this._handleToggle,
      defaultSwitched: this.props.defaultToggled,
      labelPosition: (this.props.labelPosition) ? this.props.labelPosition : "left"
    };
    if (this.props.hasOwnProperty('toggled')) enhancedSwitchProps.checked = this.props.toggled;

    return (<EnhancedSwitch {...enhancedSwitchProps}/>);
  },

  isToggled: function() {
    return this.refs.enhancedSwitch.isSwitched();
  },

  setToggled: function(newToggledValue) {
    this.refs.enhancedSwitch.setSwitched(newToggledValue);
  },

  _handleToggle: function(e, isInputChecked) {
    if (this.props.onToggle) this.props.onToggle(e, isInputChecked);
  },
});

module.exports = Toggle;
