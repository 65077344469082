import { connect } from 'react-redux';
import { onChangeUsername } from '../../modules/auth-data';
import Component from './username-component';
import StoreInterface from '../../store-interface';

const mapStateToProps = (state: StoreInterface) => {
  const authData = state.authData.toJS();
  const { username, loginError } = authData;
  const routing = state.routing.toJS();
  const usernameParam = routing.usernameParam;

  return {
    username,
    errorText: loginError,
    notification: usernameParam,
  };
};

export default connect(mapStateToProps, {
  onChangeUsername,
})(Component as any);
