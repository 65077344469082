import PropTypes from 'prop-types';
import React from 'react';
import createReactClass from 'create-react-class';
import ReactDOM from 'react-dom';
import Classable from '../mixins/classable';
import DateTime from '../utils/date-time';
import Locale from '../locales/en';
import _omit from 'lodash/omit';
import _debounce from 'lodash/debounce';

const HOURS_INPUT_NAME    = 'hours';
const MINUTES_INPUT_NAME  = 'minutes';
const MERIDIEM_INPUT_NAME = 'meridiem';

const TimePicker = createReactClass({
  displayName: 'TimePicker',
  mixins: [Classable],

  propTypes: {
    initialTime: PropTypes.object,
    onChange: PropTypes.func,
    hourInput: PropTypes.object,
    minuteInput: PropTypes.object,
    meridiemInput: PropTypes.object,
    meridiem: PropTypes.array,
  },

  componentDidMount: function(){
    this._handleHoursChange = this._filteredDebounceHandler(this._filterHoursField, this._handleHoursChange);
    this._handleMinutesChange = this._filteredDebounceHandler(this._filterMinutesField, this._handleMinutesChange);
    this._handleMeridiemChange = this._filteredDebounceHandler(this._filterMeridiemField, this._handleMeridiemChange);
  },

  componentDidUpdate: function(){
    var node;
    var timeValues = this._propTimeValues();
    var inputNames = [HOURS_INPUT_NAME, MINUTES_INPUT_NAME];
    if (!this._is24HourMode()) inputNames.push(MERIDIEM_INPUT_NAME);

    // update inputs with new prop values and refocus the active node
    inputNames.map((name) => {
      node = ReactDOM.findDOMNode(this.refs[`${name}Input`]);
      node.value = timeValues[name];
      if (document.activeElement === node) {
        this._handleFocus({target: node});
      }
    });
  },

  getDefaultProps: function() {
    return {
      initialTime: new Date(),
      hourInput: {},
      minuteInput: {},
      meridiemInput: {},
      meridiem: Locale.meridiem,
    };
  },

  render: function() {
    const spanProps = _omit(this.props, [
      'initialTime',
      'onChange',
      'hourInput',
      'minuteInput',
      'meridiemInput',
      'meridiem'
    ]);
    const classes = this.getClasses('c42-time-picker');
    const timeValues = this._propTimeValues();
    const hoursInputProps = this._hoursInputProps(timeValues.hours);
    const minutesInputProps = this._minutesInputProps(timeValues.minutes);

    return (
      <span className={classes} {...spanProps}>
        <input ref="hoursInput" {...hoursInputProps} />
        <input ref="minutesInput" {...minutesInputProps} />
        {this._meridiemInput(timeValues.meridiem)}
      </span>
    );
  },

  _hoursInputProps: function(value) {
    const hourInputProps = _omit(this.props.hourInput, [
      'defaultValue',
      'onChange',
      'min',
      'max',
      'type',
      'onFocus'
    ]);

    return {
      ...hourInputProps,
      min: (this._is24HourMode()) ? 0 : 1,
      max: (this._is24HourMode()) ? 23 : 12,
      defaultValue: value,
      onChange: this._handleHoursChange,
      onFocus: this._handleFocus,
      type: 'number',
      'data-test': 'c42ui-time-picker-hours',
    };
  },

  _minutesInputProps: function(value) {
    const minuteInput = _omit(this.props.minuteInput, [
      'defaultValue',
      'onChange',
      'min',
      'max',
      'type',
      'onFocus'
    ]);

    return {
      ...minuteInput,
      min: 0,
      max: 59,
      defaultValue: value,
      onChange: this._handleMinutesChange,
      onFocus: this._handleFocus,
      type: 'number',
      'data-test': 'c42ui-time-picker-minutes',
    };
  },

  _meridiemInput: function(value){
    if (value) {
      const inputProps = _omit(this.props.meridiemInput, [
        'defaultValue',
        'onFocus',
        'onChange'
      ]);

      return (
        <input
          {...inputProps}
          ref="meridiemInput"
          defaultValue={value}
          onFocus={this._handleFocus}
          onChange={this._handleMeridiemChange}
          onKeyDown={this._handleMeridiemKeyDown}
          data-test="c42ui-time-picker-meridiem" />
      );
    }
  },

  _handleFocus: function(e){
    // select all text in the input
    e.target.select();
  },

  _filterHoursField: function(e){
    var target = e.target;
    var filteredValue = this._filterNumberField(target.value);
    target.value = filteredValue || this._propTimeValues().hours;
  },

  _filterMinutesField: function(e){
    var target = e.target;
    var filteredValue = this._filterNumberField(target.value);
    target.value = filteredValue || this._propTimeValues().minutes;
  },

  // limit to 2 characters starting with an A or P
  _filterMeridiemField: function(e){
    var target = e.target;
    var value = target.value.substr(0,2);
    var filteredValue = value.match(/^[A|a|P|p]\w*/) && value;
    target.value = filteredValue || this._propTimeValues().meridiem;
  },

  // limit to 2 characters between 0 and 9
  _filterNumberField: function(value){
    var value = value.substr(0,2);
    return value.match(/^[0-9]{1,2}$/) && value;
  },

  _filteredDebounceHandler: function(filter, handler) {
    handler = _debounce(handler, 300);

    return function(e){
      // Must call `e.persist` on events passed to debounced functions or `e.target` will be null.
      e.persist();
      filter(e);
      handler(e);
    };
  },

  _handleHoursChange: function(e){
    var {value, max, min} = e.target;
    value = this._validateNumberField(value, max, min);

    this._handleTimeChange(e, HOURS_INPUT_NAME, value);
  },

  _handleMinutesChange: function(e){
    var {value, max, min} = e.target;
    value = this._validateNumberField(value, max, min);

    this._handleTimeChange(e, MINUTES_INPUT_NAME, value);
  },

  _handleMeridiemChange: function(e){
    var value;
    var lowerV = e.target.value.toLowerCase();

    // Match the first letter of the new value with a valid value.
    [this.props.meridiem[0], this.props.meridiem[1]].map(function(m){
      if (lowerV[0] === m.toLowerCase()[0]) {
        value = m;
      }
    });

    this._handleTimeChange(e, MERIDIEM_INPUT_NAME, value);
  },

  _handleMeridiemKeyDown: function(e){
    var keyCode = e.keyCode;

    // return unless keyCode is up or down arrow
    if (keyCode !== 38 && keyCode !== 40) { return; }

    var meridiem0 = this.props.meridiem[0];
    var meridiem1 = this.props.meridiem[1];

    if (e.target.value === meridiem0) {
      e.target.value = meridiem1;
    } else {
      e.target.value = meridiem0;
    }

    this._handleMeridiemChange(e);
  },

  _validateNumberField: function(value, max, min){
    value = parseInt(value, 10);
    max = parseInt(max, 10);
    min = parseInt(min, 10);

    if (isNaN(value)){
      return null;
    } else if (value > max) {
      return max;
    } else if (value < min) {
      return min;
    } else {
      return value;
    }
  },

  _handleTimeChange: function(e, inputName, value){
    var timeValues = this._propTimeValues();

    if (value == null) {
      // Invalid change.
      // Reset the value and don't call `onChange`.
      e.target.value = timeValues[inputName];
      return this._handleFocus(e);
    }

    var date, hours, pmMeridiem;
    date = DateTime.clone(this.props.initialTime);
    pmMeridiem = this.props.meridiem[1];

    if (inputName === HOURS_INPUT_NAME) {
      if(!this._is24HourMode()){
        value = DateTime.hours12To24(value, timeValues.meridiem === pmMeridiem);
      }
      date.setHours(value);
    } else if (inputName === MERIDIEM_INPUT_NAME) {
      hours = DateTime.hours12To24(timeValues.hours, value === pmMeridiem);
      date.setHours(hours);
    } else if (inputName === MINUTES_INPUT_NAME) {
      date.setMinutes(value);
      value = this._formatMinutes(value);
    }

    e.target.value = value;
    this._handleFocus(e);
    this.props.onChange && this.props.onChange(date);
  },

  _propTimeValues: function(){
    var date = this.props.initialTime;
    var minutes = this._formatMinutes(date.getMinutes());

    if (this._is24HourMode()) {
      return {
        hours: date.getHours(),
        minutes: minutes,
      };
    } else {
      return {
        hours: DateTime.get12Hour(date),
        minutes: minutes,
        meridiem: DateTime.getMeridiem(date, this.props.meridiem[0], this.props.meridiem[1]),
      };
    }
  },

  _formatMinutes: function(minutes){
    return minutes < 10 ? `0${minutes}` : minutes;
  },

  _is24HourMode(){
    return this.props.meridiem.length !== 2;
  },
});

module.exports = TimePicker;
