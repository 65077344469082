import PropTypes from 'prop-types';
import React from 'react';
import IconButton from '../icon-button';
import NavigationChevronLeft from '../svg-icons/navigation-chevron-left';
import NavigationChevronRight from '../svg-icons/navigation-chevron-right';
import SlideInTransitionGroup from '../transition-groups/slide-in';
import Locale from '../locales/en';

class CalendarToolbar extends React.Component {
  static propTypes = {
    displayDate: PropTypes.object.isRequired,
    onLeftClick: PropTypes.func,
    onRightClick: PropTypes.func,
    months: PropTypes.array,
  };

  static defaultProps = {
    months: Locale.months,
  };

  state = {
    transitionDirection: 'up'
  };

  componentWillReceiveProps(nextProps) {
    var direction;

    if (nextProps.displayDate !== this.props.displayDate) {
      direction = nextProps.displayDate > this.props.displayDate ? 'up' : 'down';
      this.setState({
        transitionDirection: direction
      });
    }
  }

  render() {
    var date = this.props.displayDate;
    var month = this.props.months[date.getMonth()];
    var year = date.getFullYear();

    return (
      <div className="mui-date-picker-calendar-toolbar">

        <SlideInTransitionGroup
          className="mui-date-picker-calendar-toolbar-title"
          direction={this.state.transitionDirection}>
          <div data-test="c42ui-calendar-toolbar-title" key={month + '_' + year}>{month} {year}</div>
        </SlideInTransitionGroup>

        <IconButton
          className="mui-date-picker-calendar-toolbar-button-left"
          onClick={this.props.onLeftClick}>
            <NavigationChevronLeft/>
        </IconButton>

        <IconButton
          className="mui-date-picker-calendar-toolbar-button-right"
          onClick={this.props.onRightClick}>
            <NavigationChevronRight/>
        </IconButton>

      </div>
    );
  }
}

module.exports = CalendarToolbar;
