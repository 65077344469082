import React from 'react';
import createReactClass from 'create-react-class';
import Classable from './mixins/classable';
import _omit from 'lodash/omit';

const FontIcon = createReactClass({
  displayName: 'FontIcon',
  mixins: [Classable],

  render: function() {
    const spanProps = _omit(this.props, ['className']),
          classes = this.getClasses('mui-font-icon');

    return (
      <span {...spanProps} className={classes} />
    );
  },
});

module.exports = FontIcon;
