import StoreInterface from '../store-interface';
import Redux from 'redux-thunk';
import React from 'react';

interface PartialFormEventHandler {
  (event: { target: { value: string } }): void;
}

// Binding this to the onBlur event allows us to track whether a user has focused a field without
// setting any text on it.
export function sendEmptyTextIfNull(callback: PartialFormEventHandler, testValue: any) {
  if (testValue == null) {
    callback({ target: { value: '' } });
  }
}

// Creates a function to dispatch the value of the event target
export function inputValueDispatcher(
  type: string,
  storeKey: string,
  e: React.FormEvent<any>,
): Redux.ThunkAction<void, StoreInterface, void> {
  return (dispatch) =>
    dispatch({
      type,
      [storeKey]: (e.target as HTMLInputElement).value,
    });
}
