import { connect } from 'react-redux';
import { onSubmitResetTotp } from '../../modules/reset-totp-data';
import StoreInterface from '../../store-interface';
import Reset2faLanding, { Reset2faLandingProps } from './reset-2fa-landing';
import { toggleWorkingScreen } from '../../modules/main-data';

// this function takes the global store state and maps it onto properties to pass to the underlying component
const mapStateToProps = (state: StoreInterface) => {
  const routing = state.routing.toJS();
  const resetTotpState = state.reset2faData.toJS();

  return {
    token: routing.totpResetTokenParam,
    isUpdatingTotp: resetTotpState.isUpdatingTotp,
  } as Reset2faLandingProps;
};

// connect wraps a component with a container
// the container is store aware, whereas the component just knows about the props that are given to it
export default connect(mapStateToProps, {
  toggleWorkingScreen,
  resetTotp: onSubmitResetTotp,
})(Reset2faLanding);
