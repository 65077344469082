import * as React from 'react';
import Text from '../../shared/Text';
import FormValidationMessage from './form-validation-message';
import TextField from '@c42/ui/lib/text-field';
import { sendEmptyTextIfNull } from '../utils/input-handlers';
import { validatePassword, ComplexityError } from '@cpg/password-complexity';
import { PasswordComplexity } from './password-complexity';

const FORM_CONTROL_WRAPPER_CLASSNAME = 'form-control-wrapper';

function submitHandler(
  submitForm: (password: string) => void,
  password: string | null,
  e: React.FormEvent<any>,
) {
  e.preventDefault();
  submitForm(password);
}

interface ResetPasswordProps {
  error: string | null;
  isFormSubmitted: boolean;
  submitForm: (password: string) => void;
  password: string | null;
  confirmPassword: string | null;
  onChangePassword: React.FormEventHandler<any>;
  onChangeConfirmPassword: React.FormEventHandler<any>;
}

const ResetPassword: React.StatelessComponent<ResetPasswordProps> = (props) => {
  const {
    error,
    isFormSubmitted,
    password,
    confirmPassword,
    onChangePassword,
    onChangeConfirmPassword,
  } = props;

  const complexityErrors = validatePassword(password);

  const isConfirmPasswordEmpty = !confirmPassword;
  const isPasswordMatching = password === confirmPassword;
  const isConfirmPasswordInvalid = confirmPassword != null && (isConfirmPasswordEmpty || !isPasswordMatching);
  const isFormValid = !(complexityErrors.length || !isPasswordMatching);

  let passwordErrorText;
  if (complexityErrors.indexOf(ComplexityError.EMPTY) >= 0) {
    passwordErrorText = Text.get('_validate_password_required');
  } else if (complexityErrors.length) {
    passwordErrorText = Text.get('_validate_password_requirements');
  }

  let confirmPasswordErrorText;
  if (isConfirmPasswordInvalid && isConfirmPasswordEmpty) {
    confirmPasswordErrorText = Text.get('_validate_password_confirm_required');
  } else if (isConfirmPasswordInvalid && !isPasswordMatching) {
    confirmPasswordErrorText = Text.get('login_error_passwordConfirm');
  }

  return (
    <form
      onSubmit={submitHandler.bind(null, props.submitForm, props.password)}
      className="reset-password-form"
      name="resetPasswordFrm"
      noValidate
    >
      <FormValidationMessage error={error} />
      <TextField
        displayErrorInLabel
        className={FORM_CONTROL_WRAPPER_CLASSNAME}
        type="password"
        data-test="password-input"
        disabled={isFormSubmitted}
        value={password || ''}
        errorText={passwordErrorText}
        autoComplete="off"
        floatingLabelText={Text.get('login_new_password')}
        onBlur={sendEmptyTextIfNull.bind(null, onChangePassword, password)}
        onChange={onChangePassword}
      />
      <TextField
        displayErrorInLabel
        className={FORM_CONTROL_WRAPPER_CLASSNAME}
        type="password"
        data-test="password-confirm-input"
        disabled={isFormSubmitted}
        value={confirmPassword || ''}
        errorText={confirmPasswordErrorText}
        autoComplete="off"
        floatingLabelText={Text.get('login_confirm_new_password')}
        onBlur={sendEmptyTextIfNull.bind(null, onChangeConfirmPassword, confirmPassword)}
        onChange={onChangeConfirmPassword}
      />
      <PasswordComplexity errors={complexityErrors} />
      <div className="form-control-wrapper submit">
        <input
          type="submit"
          value={Text.get('login_reset_password')}
          disabled={!isFormValid}
          className="btn btn-default btn-flat btn-customizable cpg-blue c42-button"
        />
      </div>
    </form>
  );
};

export default ResetPassword;
