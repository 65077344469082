import * as React from 'react';
import LoginStepUsername from '../../containers/login-form/step-username';
import LoginStepPassword from '../../containers/login-form/step-password';
import LoginStepTotpConfig from '../../containers/login-form/step-totp-config';
import LoginStepTotpSNWarning from './step-totp-sn-warning';
import LoginStepUsernameMismatchWarning from '../../containers/login-form/step-username-mismatch';
import LoginStepAgentLoginError from '../../containers/login-form/step-agent-login-error';

import {
  STEP_USERNAME,
  STEP_PASSWORD,
  STEP_TOTP_CONFIG,
  STEP_TOTP_SN_WARNING,
  STEP_USERNAME_MISMATCH,
  STEP_AGENT_LOGIN_ERROR
} from '../../modules/auth-data';

interface Props {
  currentStep: string;
}

export default function LoginFormWizard({ currentStep }: Props) {
  switch (currentStep) {
    default:
    case STEP_USERNAME:
      return <LoginStepUsername />;

    case STEP_PASSWORD:
      return <LoginStepPassword />;

    case STEP_TOTP_CONFIG:
      return <LoginStepTotpConfig />;

    case STEP_TOTP_SN_WARNING:
      return <LoginStepTotpSNWarning />;

    case STEP_USERNAME_MISMATCH:
      return <LoginStepUsernameMismatchWarning />;

    case STEP_AGENT_LOGIN_ERROR:
      return <LoginStepAgentLoginError />;
  }
}
