import * as React from 'react';
import Text, {TextKey} from '../../../shared/Text';
import UsernameInput from '../inputs/username-component';
import PasswordInput from '../inputs/password-component';
import { Forms } from '../../../shared/dataTest';
import FormValidationMessage from '../form-validation-message';
import { RequestResetBody } from '../../modules/reset-totp-data';

interface SubmitHandler extends RequestResetBody {
  submitForm: (body: RequestResetBody) => void;
}
function submitHandler({ submitForm, username, password }: SubmitHandler, e: React.FormEvent<any>) {
  e.preventDefault();
  submitForm({ username, password });
}

function resetHandler(props: RequestResetTotpStateProps, e: React.MouseEvent<any>) {
  e.preventDefault();
  const { resetForm } = props;
  resetForm();
}
export interface RequestResetTotpStateProps {
  error: string | null;
  password: string | null;
  username: string | null;
  isUpdatingTotp: boolean;
  isFormDisabled?: boolean;
  notificationKey?: TextKey | null;
  resetForm: () => void;
}
export interface RequestResetTotpProps extends RequestResetTotpStateProps {
  submitForm: (password: string) => void;
  onChangeUsername: React.FormEventHandler<any>;
  onChangePassword: React.FormEventHandler<any>;
}

const RequestResetTotp: React.StatelessComponent<RequestResetTotpProps> = (props) => {
  const {
    error,
    password,
    isFormDisabled,
    username,
    notificationKey,
    submitForm,
    onChangeUsername,
    onChangePassword,
  } = props;

  const isFormValid = Boolean(password) && Boolean(username);
  const hasNotification = !!notificationKey;

  return (
    <form
      onSubmit={submitHandler.bind(null, { submitForm, username, password })}
      data-test={Forms.RESET_2FA}
      className="reset-totp-form"
      name="resetTotpFrm"
      noValidate
    >
      {error && <FormValidationMessage error={error} dataTest="auth-failed-msg" />}
      <UsernameInput isDisabled={isFormDisabled} username={username} onChangeUsername={onChangeUsername} />
      <PasswordInput password={password} isDisabled={isFormDisabled} onChangePassword={onChangePassword} />
      <div className="form-control-wrapper submit">
        <input
          type="submit"
          value={Text.get('login_reset_2fa')}
          disabled={!isFormValid}
          className="btn btn-default btn-flat btn-customizable cpg-blue cpg-button"
        />
      </div>
      <div className="link">
        <a href="#" data-test="forgot-password-back-to-signin" onClick={resetHandler.bind(this, props)}>
          {Text.get('login_back_to_sign_in')}
        </a>
      </div>
      {hasNotification && (
        <div className="success" data-test="notification">
          {(notificationKey === 'login_totp_reset_check_email') && (
            <div>
              <p>{Text.get('login_totp_reset_check_email')}</p>
              <p>{Text.get('login_totp_reset_did_not_receive')}</p>
            </div>
          )}
          {(notificationKey !== 'login_totp_reset_check_email') && Text.get(notificationKey)}
        </div>
      )}
    </form>
  );
};

export default RequestResetTotp;
