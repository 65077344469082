import PropTypes from 'prop-types';
import React from 'react';
import createReactClass from 'create-react-class';
import ReactDOM from 'react-dom';
import Classable from './mixins/classable';
import DomIdable from './mixins/dom-idable';
import EnhancedTextarea from './enhanced-textarea';
import _omit from 'lodash/omit';
import _inRange from 'lodash/inRange';
import {valueLinkDeprecation} from './utils/value-link-deprecation';

const VERY_WEAK = 0;
const WEAK = 1;
const GOOD = 2;
const STRONG = 3;
const VERY_STRONG = 4;
const PASSWORD_METER_CLASS_NAME_MAP = {};
PASSWORD_METER_CLASS_NAME_MAP[VERY_WEAK] = 'very-weak';
PASSWORD_METER_CLASS_NAME_MAP[WEAK] = 'weak';
PASSWORD_METER_CLASS_NAME_MAP[GOOD] = 'good';
PASSWORD_METER_CLASS_NAME_MAP[STRONG] = 'strong';
PASSWORD_METER_CLASS_NAME_MAP[VERY_STRONG] = 'very-strong';

function charClassesObj(classes) {
  var obj = {};
  for (var c in classes) {
    if (classes[c]) {
      var key = '_passwordmeter_' + c;
      obj[key] = true;
    }
  }
  return obj;
}

var TextField = createReactClass({
  displayName: 'TextField',
  mixins: [Classable, DomIdable],
  _isMounted: false,

  propTypes: {
    errorText: PropTypes.string,
    displayErrorInLabel: PropTypes.bool,
    floatingLabelText: PropTypes.string,
    isStaticLabel: PropTypes.bool,
    hintText: PropTypes.string,
    id: PropTypes.string,
    passwordMeter: PropTypes.bool,
    passwordStrength: PropTypes.number,
    multiLine: PropTypes.bool,
    onBlur: PropTypes.func,
    onChange: PropTypes.func,
    onFocus: PropTypes.func,
    onKeyDown: PropTypes.func,
    onEnterKeyDown: PropTypes.func,
    type: PropTypes.string,
    bordered: PropTypes.bool,
    onPasswordStrengthChange: PropTypes.func,
    short: PropTypes.bool,
  },

  getDefaultProps: function() {
    return {
      type: 'text'
    };
  },

  getInitialState: function() {
    var passwordStrength = {};
    var hasValue = (
      this.props.value || this.props.defaultValue
    );

    if (this.props.passwordStrength != null) {
      passwordStrength.strength = this.props.passwordStrength;
    } else if (this.props.passwordMeter) {
      passwordStrength = this._passwordStrength(hasValue);
    }

    return {
      errorText: this.props.errorText,
      passwordStrength: passwordStrength.strength,
      missingPasswordRequirements: passwordStrength.missingRequirements,
      hasValue: hasValue
    };
  },

  componentWillUnmount: function () {
    this._isMounted = false;
  },

  componentWillReceiveProps: function(nextProps) {
    var hasErrorProp = nextProps.hasOwnProperty('errorText');
    var hasValueProp = nextProps.hasOwnProperty('value');
    var hasNewDefaultValue = nextProps.defaultValue !== this.props.defaultValue;
    var newState = {};
    var newPasswordStrength = {};

    if (hasValueProp) {
      newState.hasValue = nextProps.value;
    } else if (hasNewDefaultValue) {
      newState.hasValue = nextProps.defaultValue;
    }

    if(nextProps.passwordStrength != null){
      newState.passwordStrength = nextProps.passwordStrength;
    } else if (newState.hasValue && nextProps.passwordMeter) {
      // calculate new passwordStrength from new value prop
      newPasswordStrength = this._passwordStrength(newState.hasValue);
      newState.passwordStrength = newPasswordStrength.strength;
      newState.missingPasswordRequirements = newPasswordStrength.missingRequirements;
    }

    if (hasErrorProp) newState.errorText = nextProps.errorText;
    if (newState) this.setState(newState);
  },

  render: function() {
    valueLinkDeprecation(this.props);

    let {
      errorText,
      floatingLabelText,
      multiLine,
      short,
      isStaticLabel,
      ...other
    } = this.props;

    other = _omit(other, [
      'className',
      'displayErrorInLabel',
      'hintText',
      'id',
      'onBlur',
      'onChange',
      'onFocus',
      'onEnterKeyDown',
      'type',
      'passwordMeter',
      'passwordStrength',
      'bordered'
    ]);

    var classes = this.getClasses('mui-text-field', {
      'mui-has-error': errorText,
      'mui-has-floating-labels': floatingLabelText,
      'mui-is-static-label': isStaticLabel,
      'mui-has-value': this.state.hasValue,
      'mui-is-disabled': this.props.disabled,
      'mui-is-focused': this.state.isFocused,
      'mui-is-multiLine': multiLine,
      'mui-bordered': this.props.bordered,
      'mui-is-short': short,
    });

    var inputId = this.props.id || this.getDomId();

    var errorTextElement = this.state.errorText && !this.props.displayErrorInLabel ? (
      <div className="mui-text-field-error">{this.state.errorText}</div>
    ) : null;

    var hintTextElement = this.props.hintText ? (
      <div className="mui-text-field-hint">{this.props.hintText}</div>
    ) : null;

    floatingLabelText = (this.state.errorText && this.props.displayErrorInLabel) ? this.state.errorText : this.props.floatingLabelText;

    var floatingLabelTextElement = this.props.floatingLabelText ? (
      <label
        className="mui-text-field-floating-label"
        htmlFor={inputId}>
        {floatingLabelText}
      </label>
    ) : null;

    var passwordMeter = this.props.passwordMeter ? (
      <span ref="meter" className={this._passwordMeterClassName()} data-test="password-strength-meter">
          <svg version="1.1"
             xmlns="http://www.w3.org/2000/svg"
             x="0px" y="0px" width="15px" height="18px" viewBox="0 0 15 18">
            <rect id="strengthMeterBar1" y="15" width="15" height="3"/>
            <rect id="strengthMeterBar2" y="10" width="15" height="3"/>
            <rect id="strengthMeterBar3" y="5" width="15" height="3"/>
            <rect id="strengthMeterBar4" width="15" height="3"/>
          </svg>
      </span>
    ) : null;

    var dataTest = this.props.type ? 'text-field-'+this.props.type : 'text-field-text';

    if(this.props['data-test']) {
      dataTest = this.props['data-test'];
    }

    var inputProps;
    var inputElement;
    inputProps = {
      ref: 'input',
      className: 'mui-text-field-input',
      id: inputId,
      'data-test': dataTest,
      onBlur: this._handleInputBlur,
      onChange: this._handleInputChange,
      onFocus: this._handleInputFocus,
      onKeyDown: this._handleInputKeyDown
    };

    // handle padding for the password
    if (passwordMeter) {
      if (!inputProps.style) {
        inputProps.style = { paddingRight: 15 };
      } else {
        inputProps.style.paddingRight = 15;
      }
    }

    let wrapperStyles = {};
    if (multiLine) {
      wrapperStyles.height = '100%';
      inputElement = (
        <EnhancedTextarea
          {...other}
          {...inputProps}
          onHeightChange={this._handleTextAreaHeightChange}
          textareaClassName="mui-text-field-textarea" />
      );
    } else {
      const otherProps = _omit(other, [
        'hintText',
        'floatingLabelText',
        'errorText',
        'displayErrorInLabel',
        'isStaticLabel',
        'passwordMeter',
        'passwordStrength',
        'multiLine',
        'bordered',
        'onPasswordStrengthChange',
        'short',
        'passwordMeter',
        'passwordStrength'
      ]);
      inputElement = (
        <input
          {...otherProps}
          {...inputProps}
          type={this.props.type} />
      );
    }

    return (
      <div className={classes} style={wrapperStyles}>

        {floatingLabelTextElement}
        {hintTextElement}
        {inputElement}
        {passwordMeter}

        <hr className="mui-text-field-underline" />
        <hr className="mui-text-field-focus-underline" />

        {errorTextElement}

      </div>
    );
  },

  componentDidMount: function() {
    if (this.props.multiLine) {
      this._handleTextAreaHeightChange();
    }
    this._isMounted = true;
  },

  blur: function() {
    if (this._isMounted) this._getInputNode().blur();
  },

  clearValue: function() {
    this.setValue('');
  },

  focus: function() {
    if (this._isMounted) this._getInputNode().focus();
  },

  getValue: function() {
    return this._isMounted ? this._getInputNode().value : undefined;
  },

  setErrorText: function(newErrorText) {
    if (process.NODE_ENV !== 'production' && this.props.hasOwnProperty('errorText')) {
      console.error('Cannot call TextField.setErrorText when errorText is defined as a property.');
    } else if (this._isMounted) {
      this.setState({errorText: newErrorText});
    }
  },

  setValue: function(newValue) {
    if (process.NODE_ENV !== 'production' && this._isControlled()) {
      console.error('Cannot call TextField.setValue when value is defined as a property.');
    } else if (this._isMounted) {
      this._getInputNode().value = newValue;
      this.setState({hasValue: newValue});
    }
  },

  _getInputNode: function() {
    return this.props.multiLine ?
      this.refs.input.getInputNode() : ReactDOM.findDOMNode(this.refs.input);
  },

  _handleInputBlur: function(e) {
    this.setState({isFocused: false});
    if (this.props.onBlur) this.props.onBlur(e);
  },

  _handleInputChange: function(e) {
    this.setState({hasValue: e.target.value});
    if (this.props.onChange) this.props.onChange(e, e.target.value);
    if (this.props.passwordMeter && this.props.passwordStrength == null) this._setMeter(e);
  },

  _handleInputFocus: function(e) {
    this.setState({isFocused: true});
    if (this.props.onFocus) this.props.onFocus(e);
  },

  _handleInputKeyDown: function(e) {
    if (e.keyCode === 13 && this.props.onEnterKeyDown) this.props.onEnterKeyDown(e);
    if (this.props.onKeyDown) this.props.onKeyDown(e);
  },

  _handleTextAreaHeightChange: function(e, height) {
    // the bordered value is based on css padding pixels
    // to ensure the container text field height matches the bottom
    // bordered when the text field is bordered
    var newHeight = this.props.bordered ? height + 5 : height;
    if (this.props.floatingLabelText) newHeight += 48;
    ReactDOM.findDOMNode(this).style.minHeight = newHeight + 'px';
  },

  _isControlled: function() {
    return this.props.hasOwnProperty('value');
  },

  _handlePasswordStrengthChange: function(){
    if (this.props.onPasswordStrengthChange) {
      this.props.onPasswordStrengthChange({
        strength: this.state.passwordStrength,
        missingRequirements: this.state.missingPasswordRequirements
      });
    }
  },

  _setMeter: function(e) {
    var {strength, missingRequirements} = this._passwordStrength(e.target.value);
    this.setState({
      passwordStrength: strength,
      missingPasswordRequirements: missingRequirements
    }, this._handlePasswordStrengthChange);
  },

  _passwordMeterClassName: function(){
    var {passwordStrength} = this.state;
    var meterClass = 'c42ui-password-meter';

    if (passwordStrength) {
      meterClass += `-${PASSWORD_METER_CLASS_NAME_MAP[passwordStrength]}`;
    }

    return meterClass;
  },

  _passwordStrength: function(passwd) {
    passwd = passwd || '';

    var missingClasses = {
      lowercase: true,
      uppercase: true,
      number: true,
      symbol: true,
      too_short: true
    };

    var score = 0;

    if (passwd.match(/.*[a-z].*/)) {
      score++; // lowercase
      delete missingClasses.lowercase;
    }
    if (passwd.match(/.*[A-Z].*/)) {
      score++; // uppercase
      delete missingClasses.uppercase;
    }
    if (passwd.match(/.*[0-9].*/)) {
      score++; // number
      delete missingClasses.number;
    }
    if (passwd.match(/.*[^a-zA-Z0-9 ].*/)) {
      score++; // symbol
      delete missingClasses.symbol;
    }

    if (passwd.length === 0) { score = VERY_WEAK; }
    // override to WEAK score if between 0 and 8 characters, exclusive of 0 and 8.
    else if (_inRange(passwd.length, 1, 8)) { score = WEAK; }
    else {
      delete missingClasses.too_short;
    }

    return { strength: score, missingRequirements: charClassesObj(missingClasses) };
  },
});

module.exports = TextField;
