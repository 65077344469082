import * as Immutable from 'immutable';
import { LOCATION_CHANGE, push } from 'react-router-redux';
import { LOGIN_INDEX } from '../constants/routes';
import StoreInterface, { Routing } from '../store-interface';
import Redux from 'redux-thunk';
import ReactRouter from 'react-router';

// visibleForTesting
export const whitelistRegex = new RegExp(
  '^https?://([a-zA-Z0-9-_]+[.])*(?:' + 'crashplan|crashplanpro|crashplan' + ')\\.com(:[0-9]*)?(/.*)?$',
);

// test if included in Whitelist or is only a partial url
function isValidRedirect(testParam) {
  testParam = testParam || '//';
  return testParam.indexOf('//') === -1 || whitelistRegex.test(testParam);
}

/////////////////////
//  Action Types   //
/////////////////////

// visibleForTesting
export const SET_QUERY_PARAMS = 'login/routing/SET_QUERY_PARAMS';
export const CLEAR_USERNAME_PARAM = 'login/routing/CLEAR_USERNAME_PARAM';

/////////////////////
//     Reducer     //
/////////////////////

export const initialState = Immutable.fromJS({
  locationBeforeTransitions: null,
  logoutParam: null, // logout query param
  redirectParam: null, // redirect query param
  passwordResetTokenParam: null, // password reset token query param
  authTokenParam: null, // token query param
  usernameParam: null, // username query param
  uuidParam: null, // uuid query param
} as Routing);

export default (state = initialState, action) => {
  switch (action.type) {
    case LOCATION_CHANGE:
      return state.merge({
        locationBeforeTransitions: action.payload,
        passwordResetTokenParam: action.payload?.query?.treset,
        totpResetTokenParam: action.payload?.query?.treset,
      });
    case SET_QUERY_PARAMS:
      const { logout, to, treset, tt, username, reset, uuid } = action.query;
      return state.merge({
        logoutParam: logout,
        redirectParam: isValidRedirect(to) ? to : null,
        passwordResetTokenParam: treset,
        totpResetTokenParam: reset || treset,
        authTokenParam: tt,
        usernameParam: username || null,
        uuidParam: uuid,
      });
    case CLEAR_USERNAME_PARAM:
      return state.merge({ usernameParam: null });
    default:
      return state;
  }
};

/////////////////////
// Action Creators //
/////////////////////

export function goToLogin(): Redux.ThunkAction<void, StoreInterface, void> {
  return (dispatch) => dispatch(push(LOGIN_INDEX as any));
}

export function setInitialQueryParams(
  query: ReactRouter.Query,
): Redux.ThunkAction<void, StoreInterface, void> {
  return (dispatch) =>
    dispatch({
      type: SET_QUERY_PARAMS,
      query,
    });
}
