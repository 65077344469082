var MathExtras = require('./math-extras');
var _padStart = require('lodash/padStart');

module.exports = {

  now: function() {
    return new Date();
  },

  humanizeDate(date, reference) {
    if (!reference) {
      reference = this.now();
    }
    var formatted = this.format(date);
    var today = this.format(reference);
    var yesterday = this.clone(reference);
    yesterday.setDate(yesterday.getDate() - 1);
    yesterday = this.format(yesterday);
    switch (formatted) {
    case today:
      return 'Today';
    case yesterday:
      return 'Yesterday';
    default:
      return formatted;
    }
  },

  addDays: function(d, days) {
    var newDate = this.clone(d);
    newDate.setDate(d.getDate() + days);
    return newDate;
  },

  addMonths: function(d, months) {
    var newDate = this.clone(d);
    newDate.setMonth(d.getMonth() + months);
    return newDate;
  },

  clone: function(d) {
    return new Date(d.getTime());
  },

  getDaysInMonth: function(d) {
    var resultDate = this.getFirstDayOfMonth(d);

    resultDate.setMonth(resultDate.getMonth() + 1);
    resultDate.setDate(resultDate.getDate() - 1);

    return resultDate.getDate();
  },

  getFirstDayOfMonth: function(d) {
    return new Date(d.getFullYear(), d.getMonth(), 1);
  },

  getWeekArray: function(d, weekStart) {
    weekStart = parseInt(weekStart) || 0;

    var dayArray = [];
    var daysInMonth = this.getDaysInMonth(d);
    var daysInWeek;
    var emptyDays;
    var firstDayOfWeek;
    var week;
    var weekArray = [];

    for (var i = 1; i <= daysInMonth; i++) {
      dayArray.push(new Date(d.getFullYear(), d.getMonth(), i));
    }

    while (dayArray.length) {
      firstDayOfWeek = MathExtras.mod(dayArray[0].getDay() - weekStart, 7);
      daysInWeek = 7 - firstDayOfWeek;
      emptyDays = 7 - daysInWeek;
      week = dayArray.splice(0, daysInWeek);

      for (let i = 0; i < emptyDays; i++) {
        week.unshift(null);
      }

      weekArray.push(week);
    }

    return weekArray;
  },

  format: function(date) {
    var m = date.getMonth() + 1;
    var d = date.getDate();
    var y = date.getFullYear();
    return m + '/' + d + '/' + y;
  },

  formatTime: function(date) {
    var hours    = this.get12Hour(date);
    var minutes  = _padStart(date.getMinutes(), 2, '0');
    var meridiem = this.getMeridiem(date, 'AM', 'PM');

    return `${hours}:${minutes} ${meridiem}`;
  },

  formatFull: function(date, amValue, pmValue) {
    var mdy = this.format(date);
    var h = this.get12Hour(date);
    var mi = date.getMinutes();
    var me = this.getMeridiem(date, amValue, pmValue);

    if (mi < 10) {
      mi = `0${mi}`;
    }

    return `${mdy} ${h}:${mi} ${me}`;
  },

  isLaterDate: function(d1, d2){
    return d1 && d2 && d1 > d2;
  },

  isSameDay: function(d1, d2) {
    return d1 && d2 &&
      (d1.getFullYear() === d2.getFullYear()) &&
      (d1.getMonth() === d2.getMonth()) &&
      (d1.getDate() === d2.getDate());
  },

  monthDiff: function(d1, d2) {
    var m;
    m = (d1.getFullYear() - d2.getFullYear()) * 12;
    m += d1.getMonth();
    m -= d2.getMonth();
    return m;
  },

  get12Hour: function(date) {
    var hours = date.getHours();

    if (hours > 12) {
      return hours - 12;
    } else if (hours == 0) {
      return 12;
    } else {
      return hours;
    }
  },

  hours12To24: function(hours, isEvening) {
    var hoursParsed = parseInt(hours);

    if (hoursParsed === 12) {
      hoursParsed = 0;
    }

    return isEvening ? (hoursParsed + 12) : hoursParsed;
  },

  getMeridiem: function(date, amValue, pmValue) {
    return date.getHours() < 12 ? amValue : pmValue;
  },

  startOfDay: function(d){
    d = d || this.now();
    d = this.clone(d);
    d.setHours(0);
    d.setMinutes(0);
    d.setSeconds(0);
    d.setMilliseconds(0);
    return d;
  },

  endOfDay: function(d){
    d = d || this.now();
    d = this.clone(d);
    d.setHours(23);
    d.setMinutes(59);
    d.setSeconds(59);
    d.setMilliseconds(999);
    return d;
  },

};
