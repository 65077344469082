import 'whatwg-fetch';

export const GET = 'GET';
export const POST = 'POST';

export interface RequestResponse {
  response: Response;
  body: any;
}

export class RequestError extends Error {
  name: string;
  status: number;
  message: string;

  constructor(status: number, body: any) {
    super();

    this.status = status;

    if (typeof body === 'string') {
      this.message = body;
      return;
    }

    // The Authority API can send back error messages tucked
    // inside the JSON body. Look for those messages and
    // expose them if they exist.

    if (Array.isArray(body) && body.length > 0) {
      const { name, description } = body[0];

      if (name !== undefined) {
        this.name = name;
      }

      if (description !== undefined) {
        this.message = description;
      }
    }
  }
}

/**
 * Make an HTTP request to a given URL using window.fetch.
 *
 * Options are any that can be passed to window.fetch, such as:
 *   - headers
 *   - body
 *   - mode
 *   - etc...
 *
 * Throws a RequestError when response.ok is false.
 *
 * @param {string} url
 * @param {string} method
 * @param {object} [options]
 * @throws Error | RequestError
 * @returns Promise<RequestResponse>
 */
export async function request(url: string, method: string, options: any = {}): Promise<RequestResponse> {
  if (typeof url === 'undefined') {
    throw new Error('request() requires a url');
  }

  if (typeof method === 'undefined') {
    throw new Error('request() requires a method');
  }

  const response = await window.fetch(url, { method, ...options });

  let body = await response.text();

  try {
    body = JSON.parse(body);
  } catch (error) {
    // body wasn't JSON, no big deal.
  }

  if (!response.ok) {
    const { status } = response;
    throw new RequestError(status, body);
  }

  return { response, body };
}
