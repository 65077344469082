import PropTypes from 'prop-types';
import React from 'react';
import createReactClass from 'create-react-class';
import ReactDOM from 'react-dom';
import KeyCode from './utils/key-code';
import Classable from './mixins/classable';
import DomIdable from './mixins/dom-idable';
import WindowListenable from './mixins/window-listenable';
import Paper from './paper';
import _omit from 'lodash/omit';
import { valueLinkDeprecation } from './utils/value-link-deprecation';

const EnhancedSwitch = createReactClass({
  displayName: 'EnhancedSwitch',
  mixins: [Classable, DomIdable, WindowListenable],

  propTypes: {
    id: PropTypes.string,
    inputType: PropTypes.string.isRequired,
    switchElement: PropTypes.element.isRequired,
    iconClassName: PropTypes.string.isRequired,
    name: PropTypes.string,
    value: PropTypes.string,
    label: PropTypes.node,
    onSwitch: PropTypes.func,
    required: PropTypes.bool,
    disabled: PropTypes.bool,
    indeterminate: PropTypes.bool,
    defaultSwitched: PropTypes.bool,
    labelPosition: PropTypes.oneOf(['left', 'right'])
  },

  windowListeners: {
    'keydown': '_handleWindowKeydown',
    'keyup': '_handleWindowKeyup'
  },

  getDefaultProps: function() {
    return {
      iconClassName: ''
    };
  },

  getInitialState: function() {
    return {
      switched: this.props.defaultSwitched,
      isKeyboardFocused: false
    };
  },

  componentWillMount: function() {
    valueLinkDeprecation(this.props);

    if (this.props.hasOwnProperty('checked')) {
      var checked = this.props.checked;
      if (checked === undefined) {
        checked = this.props.defaultSwitched;
      }
      this.setState({switched: checked});
    }
  },

  componentWillReceiveProps: function(nextProps) {
    var hasCheckedLinkProp = nextProps.hasOwnProperty('checkedLink');
    var hasCheckedProp = nextProps.hasOwnProperty('checked');
    var hasToggledProp = nextProps.hasOwnProperty('toggled');
    var newState = {};

    if (hasCheckedProp) {
      newState.switched = nextProps.checked;
    } else if (hasToggledProp) {
      newState.switched = nextProps.toggled;
    } else if (hasCheckedLinkProp) {
      newState.switched = nextProps.checkedLink.value;
    }

    if (newState) this.setState(newState);
  },

  render: function() {
    var {
      label,
      onMouseEnter,
      onMouseLeave,
      iconClassName,
      ...other
    } = this.props;
    const childProps = _omit(other, [
      'type',
      'name',
      'value',
      'onSwitch',
      'defaultSwitched',
      'onBlur',
      'onFocus',
      'onTouchStart',
      'onTouchEnd',
      'inputType',
      'switchElement',
      'labelPosition',
      'indeterminate'
    ]);

    var classes = this.getClasses('mui-enhanced-switch', {
      'mui-is-switched': !this.props.indeterminate && this.state.switched,
      'mui-is-disabled': this.props.disabled,
      'mui-is-required': this.props.required,
      'mui-is-indeterminate': this.props.indeterminate && this.state.switched
    });

    var inputId = this.props.id || this.getDomId();

    var labelElement = this.props.label ? (
      <label className="mui-switch-label" htmlFor={inputId}>
        {this.props.label}
      </label>
    ) : null;

    var inputProps = {
      ref: "checkbox",
      id: inputId,
      type: this.props.inputType,
      name: this.props.name,
      value: this.props.value,
      defaultChecked: this.props.defaultSwitched,
      onBlur: this._handleBlur,
      onFocus: this._handleFocus,
      onTouchStart: this._handleTouchStart,
      onTouchEnd: this._handleTouchEnd
    };

    if (!this.props.hasOwnProperty('checkedLink')) {
      inputProps.onChange = this._handleChange;
    }

    var inputElement = (
      <input
        {...childProps}
        {...inputProps}
        className="mui-enhanced-switch-input"/>
    );

    iconClassName += ' mui-enhanced-switch-wrap';

    var switchElement = (this.props.iconClassName.indexOf("toggle") == -1) ? (
        <div className={iconClassName}>
          {this.props.switchElement}
        </div>
      ) : (
        <div className={iconClassName}>
          <div className="mui-toggle-track" />
          <Paper className="mui-toggle-thumb" zDepth={0} />
        </div>
    );

    var labelPositionExist = this.props.labelPosition;

    var isCheckbox = !this.props.hasOwnProperty('checked');

    var labelProps = {
      className: isCheckbox ? 'cb-wrap':'rb-wrap',
      onMouseEnter: onMouseEnter,
      onMouseLeave: onMouseLeave
    };

    var inputAndSwitch = (
      <label {...labelProps}>
        {inputElement}
        {switchElement}
      </label>
    );

    // Position is left if not defined or invalid.
    var elementsInOrder = (labelPositionExist &&
      (this.props.labelPosition.toUpperCase() === "RIGHT")) ? (
        <div>
          {inputAndSwitch}
          {labelElement}
        </div>
      ) : (
        <div>
          {labelElement}
          {inputAndSwitch}
        </div>
    );

    return (
      <div className={classes}>
          {elementsInOrder}
      </div>
    );
  },

  isSwitched: function() {
    return ReactDOM.findDOMNode(this.refs.checkbox).checked;
  },

  // no callback here because there is no event
  setSwitched: function(newSwitchedValue) {
    if (!this.props.hasOwnProperty('checked') || this.props.checked == false) {
      this.setState({switched: newSwitchedValue});
      ReactDOM.findDOMNode(this.refs.checkbox).checked = newSwitchedValue;
    } else if (process.NODE_ENV !== 'production') {
      var message = 'Cannot call set method while checked is defined as a property.';
      console.error(message);
    }
  },

  getValue: function() {
    return ReactDOM.findDOMNode(this.refs.checkbox).value;
  },

  isKeyboardFocused: function() {
    return this.state.isKeyboardFocused;
  },

  _handleChange: function(e) {

    this._tabPressed = false;
    this.setState({
      isKeyboardFocused: false
    });

    var isInputChecked = ReactDOM.findDOMNode(this.refs.checkbox).checked;

    if (!this.props.hasOwnProperty('checked')) this.setState({switched: isInputChecked});
    if (this.props.onSwitch) this.props.onSwitch(e, isInputChecked);
  },

  // Checkbox inputs only use SPACE to change their state. Using ENTER will
  // update the ui but not the input.
  _handleWindowKeydown: function(e) {
    if (e.keyCode == KeyCode.TAB) this._tabPressed = true;
    if (e.keyCode == KeyCode.SPACE && this.state.isKeyboardFocused) {
      this._handleChange(e);
    }
  },

  _handleWindowKeyup: function(e) {
    if (e.keyCode == KeyCode.SPACE && this.state.isKeyboardFocused) {
      this._handleChange(e);
    }
  },

  _handleBlur: function(e) {
    this.setState({
      isKeyboardFocused: false
    });

    if (this.props.onBlur) this.props.onBlur(e);
  },

  _handleFocus: function(e) {
    //setTimeout is needed becuase the focus event fires first
    //Wait so that we can capture if this was a keyboard focus
    //or touch focus
    setTimeout(function() {
      if (this._tabPressed) {
        this.setState({
          isKeyboardFocused: true
        });
      }
    }.bind(this), 150);

    if (this.props.onFocus) this.props.onFocus(e);
  },
});

module.exports = EnhancedSwitch;
