/*************************************************************
 *************************************************************
 *************************************************************
 *             DO NOT MODIFY THIS FILE DIRECTLY!             *
 *      This is generated on build from the contents of      *
 *  `src/lang/en.properties`. Any new keys should be added   *
 *                          there.                           *
 *************************************************************
 *************************************************************
 *************************************************************/
import { default as c42Text } from '@cpg/i18n/dist/Text';

export type TextKey =
  | '_button_continue' /* Continue */
  | '_placeholder_username_or_email' /* Username or email address */
  | '_placeholder_password' /* Password */
  | '_placeholder_enter_code' /* Authentication code */
  | '_validate_username_or_email' /* Username or email address required */
  | '_validate_password_header' /* A valid password must */
  | '_validate_password_required' /* Password is required */
  | '_validate_password_minlength' /* Be at least ${MinLength} characters long */
  | '_validate_password_number' /* Contain at least 1 number (0-9) */
  | '_validate_password_lower' /* Contain at least 1 lowercase letter */
  | '_validate_password_upper' /* Contain at least 1 uppercase letter */
  | '_validate_password_whitespace' /* Not contain any spaces */
  | '_validate_password_repeating' /* Not contain characters repeated 3 times or more */
  | '_validate_password_dictionary' /* Not be commonly used */
  | '_validate_password_requirements' /* Password does not meet the requirements */
  | '_validate_password_confirm_required' /* Password confirmation is required */
  | '_validate_totp_required' /* Authentication code is required */
  | '_flash_password_reset_check_email' /* Check your email for password reset instructions. */
  | 'sso_signin_different_account' /* Sign in with a different account */
  | 'sso_title' /* Single Sign-On */
  | 'sso_load_idps_error' /* Problem loading providers */
  | 'login_button_sign_in' /* Sign In */
  | 'login_error_passwordConfirm' /* Passwords do not match */
  | 'login_error_incorrect' /* Unable to sign in. Please check username and password. */
  | 'login_error_incorrect_totp' /* Unable to sign in. Please check username, password, and authentication code. */
  | 'login_error_auth' /* Unable to sign in, user is unauthorized. */
  | 'login_config_error_generic' /* An error occurred */
  | 'redirect_to_SMB_login_text' /* Looking for CrashPlan for Small Business login instead? Click here */
  | 'redirect_to_CrashPlan_login_text' /* Sign in to CrashPlan */
  | 'login_totp_config_headline' /* Set up two-factor authentication */
  | 'login_totp_config_description' /* Your CrashPlan administrator requires two-factor authentication to sign in with this account. */
  | 'login_totp_config_step1_headline' /* Install an authenticator */
  | 'login_totp_config_step1_details' /* Recommended: */
  | 'login_totp_config_step1_app' /* Mobile app authentication */
  | 'login_totp_config_step1_browser' /* Browser based authentication */
  | 'login_totp_config_device_ios' /* iOS */
  | 'login_totp_config_device_android' /* Android */
  | 'login_totp_config_browser_firefox' /* Firefox */
  | 'login_totp_config_browser_chrome' /* Chrome */
  | 'login_totp_config_browser_edge' /* Edge */
  | 'login_totp_config_step2_headline' /* Add account */
  | 'login_totp_config_step2_opt1_or' /* Scan QR Code below, or */
  | 'login_totp_config_step2_opt1_or_enter' /* Scan QR Code below in your authenticator or manually enter the following <strong>in your authenticator</strong>: */
  | 'login_totp_config_step2_opt2' /* View code for manual entry or API setup */
  | 'login_totp_config_step3_headline' /* Enter 6-digit verification code */
  | 'login_totp_config_step3_description' /* Provided by your authenticator (numbers only) */
  | 'login_totp_config_step3_error' /* Invalid code */
  | 'login_totp_code_tooltip' /* To get your code, go to your two-factor authentication app (for example, Google Authenticator). {{link}} */
  | 'login_totp_code_retrieve' /* Don’t have your code? */
  | 'login_totp_request_reset_success' /* Check email for instruction. */
  | 'login_totp_request_reset_error' /* Failed to request a new code. */
  | 'login_totp_reset_check_email' /* If there is an account associated with the credentials provided, you'll receive an email with instructions. */
  | 'login_totp_reset_did_not_receive' /* If you don't receive an email, check your username and password. If necessary, click "Forgot Password" below to reset your password and try again. */
  | 'login_totp_reset_success' /* Two-factor authentication reset successfully. */
  | 'login_totp_reset_error' /* Couldn't reset two-factor authentication. Try again to send a new reset link to your email. */
  | 'login_totp_reset_invalid_token' /* Unable to reset your two-factor auth. Click "Don't have your code?" to send a new reset link to your email. */
  | 'login_reset_2fa' /* Reset two-factor authentication */
  | 'login_totp_storage_node_warning_description' /* You must set up two-factor authentication to log in with this account. This can only be done on the <strong>authority server</strong>. Once you've set up two-factor authentication you may attempt to log back in to the storage node. */
  | 'login_totp_storage_node_warning_more_info' /* For more information, <a href="${url}" target="_blank">contact our Customer Champions.</a> */
  | 'login_username_mismatch_warning_current_username' /* You are currently signed in as */
  | 'login_username_mismatch_warning_attempted_username' /* However, you are attempting to sign in to the CrashPlan app as */
  | 'login_username_mismatch_warning_what_are_you_gonna_do_about_it' /* Click below to sign out of <strong>${authenticatedUsername}</strong> and sign in as <strong>${usernameParam}</strong> */
  | 'login_username_mismatch_sign_out_button' /* Sign Out */
  | 'login_error_agent_login' /* Could not sign in CrashPlan application automatically. */
  | 'login_error_agent_login_instructions' /* Close this window and try again. */
  | 'login_error_agent_login_error_code' /* Error code: ${errorCode} */
  | 'login_forgot_password' /* Forgot Password */
  | 'login_reset_password' /* Reset password */
  | 'login_new_password' /* New password */
  | 'login_confirm_new_password' /* Confirm new password */
  | 'login_password_reset_success' /* Password reset successfully. */
  | 'login_password_reset_error' /* Couldn't reset your password. */
  | 'login_password_reset_invalid_token' /* Unable to reset your password. Click "Forgot Password" below to send a new reset link to your email. */
  | 'login_back_to_sign_in' /* Back to sign in */
  | 'login_support_need_help' /* Need help? */
  | 'login_support_learn_more' /* Learn more about signing in. */;

class Text extends c42Text<TextKey> {}

const options = { warnOnMissingKeys: process.env.NODE_ENV === 'development' };
export default new Text(options);
