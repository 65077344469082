import * as React from 'react';
import Text from '../../../shared/Text';
import ReactTextHelper from '@c42/helpers/lib/utils/reactText';

const LoginStepUsernameMismatchWarning = ({authenticatedUsername, usernameParam, agentLoginSwitchUsername}) => {

  const buttonText = Text.get('login_username_mismatch_sign_out_button');

  const submitForm = (e?: React.FormEvent<any>) => {
    if (e && 'preventDefault' in e) {
      e.preventDefault();
    }
    agentLoginSwitchUsername?.();
  };

  return (
    <form onSubmit={submitForm} name="loginFrm" data-test="login-form" noValidate>
      <div className="step-username-mismatch">
        <p>
          {ReactTextHelper.createElementsFromText(Text.get('login_username_mismatch_warning_current_username'))}<br/>
          <strong>{authenticatedUsername}</strong><br/>
          {ReactTextHelper.createElementsFromText(Text.get('login_username_mismatch_warning_attempted_username'))}<br/>
          <strong>{usernameParam}</strong>
        </p>
        <p>
          {ReactTextHelper.createElementsFromText(Text.get('login_username_mismatch_warning_what_are_you_gonna_do_about_it', {authenticatedUsername, usernameParam}))}
        </p>
        <div className="form-control-wrapper submit">
          <input
            type="submit"
            value={buttonText}
            className="btn btn-default btn-flat btn-customizable cpg-blue c42-button"
            data-test="submit"
          />
        </div>
      </div>
    </form>
  );
};

export default LoginStepUsernameMismatchWarning;
