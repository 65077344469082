import 'whatwg-fetch';
import * as React from 'react';
import { Provider } from 'react-redux';
import { hashHistory, Route, Router } from 'react-router';
import { syncHistoryWithStore } from 'react-router-redux';

import {
  ROOT_INDEX,
  LOGIN_INDEX,
  SSO_INDEX,
  FORGOT_PASSWORD_INDEX,
  RESET_PASSWORD_INDEX,
  DEFAULT_REDIRECT_AFTER_LOGIN,
  RESET_TOTP_INDEX,
} from '../constants/routes';

// Containers
import ForgotPasswordContainer from '../containers/forgot-password-form-container';
import LoginWizardContainer from '../containers/login-form/wizard';
import MainContainer from '../containers/main-container';
import ResetPasswordContainer from '../containers/reset-password-form-container';
import SsoContainer from '../containers/sso-form-container';

import StoreInterface from '../store-interface';
import Store from '../store';

import '../../styles/app.scss';
import ResetTotpContainer from '../components/reset-2fa/reset-2fa-landing-container';

export function App() {
  // Create an enhanced history that syncs navigation events with the store.
  // This enhanced history object allows Redux to be aware of the routing info.

  const history = syncHistoryWithStore(hashHistory, Store, {
    selectLocationState: (state: StoreInterface) => state.routing.toJS(),
  });

  const RedirectToConsole = () => {
    window.location.replace(DEFAULT_REDIRECT_AFTER_LOGIN);
    return null;
  };

  return (
    <Provider store={Store}>
      <Router history={history}>
        <Route path={ROOT_INDEX} component={MainContainer}>
          <Route path={FORGOT_PASSWORD_INDEX} component={ForgotPasswordContainer} />
          <Route path={LOGIN_INDEX} component={LoginWizardContainer} />
          <Route path={RESET_PASSWORD_INDEX} component={ResetPasswordContainer} />
          <Route path={RESET_TOTP_INDEX} component={ResetTotpContainer} />
          <Route path={SSO_INDEX} component={SsoContainer} />
          <Route path="/landing" component={RedirectToConsole} />
        </Route>
      </Router>
    </Provider>
  );
}
