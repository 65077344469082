import { connect } from 'react-redux';
import { onSubmitRequestTotpReset, onChangePassword, onChangeUsername } from '../modules/reset-totp-data';
import RequestResetTotp, { RequestResetTotpProps } from '../components/reset-2fa/reset-2fa-request-form';
import StoreInterface from '../store-interface';
import { resetForm } from '../modules/auth-data';

// this function takes the global store state and maps it onto properties to pass to the underlying component
const mapStateToProps = (state: StoreInterface, { isDisabled }: { isDisabled?: boolean } = {}) => {
  const reset2faData = state.reset2faData.toJS();
  const { username, password, notificationKey, error, isUpdatingTotp } = reset2faData;

  return {
    username,
    password,
    notificationKey,
    error,
    isUpdatingTotp,
    isFormDisabled: isDisabled || isUpdatingTotp,
  } as Omit<RequestResetTotpProps, 'onChangePassword'>;
};

// connect wraps a component with a container
// the container is store aware, whereas the component just knows about the props that are given to it
export default connect(mapStateToProps, {
  onChangePassword,
  onChangeUsername,
  resetForm,
  submitForm: onSubmitRequestTotpReset,
})(RequestResetTotp);
