import { connect } from 'react-redux';
import StoreInterface from '../../store-interface';
import Component from '../../components/login-form/step-password';
import { onChangePassword, onChangeTotp, resetForm, submitLogin } from '../../modules/auth-data';

const mapStateToProps = (state: StoreInterface) => {
  const authData = state.authData.toJS();
  const resetPasswordData = state.resetPasswordData.toJS();
  const { username, password, totp, isUsingTotp } = authData;

  return {
    username,
    password,
    totp,
    isUsingTotp,
    error: authData.loginError,
    notification: resetPasswordData.notification,
    isFormDisabled: authData.isAuthenticating,
  };
};

export default connect(mapStateToProps, {
  onChangePassword,
  onChangeTotp,
  submitForm: submitLogin,
  resetForm,
})(Component);
