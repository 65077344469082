import { connect } from 'react-redux';
import Component from '../../components/login-form/step-username-mismatch';
import StoreInterface from '../../store-interface';
import {agentLoginSwitchUsername} from "../../modules/auth-data";

const mapStateToProps = (state: StoreInterface) => {
  const authData = state.authData.toJS();
  const routing = state.routing.toJS();
  const resetPasswordData = state.resetPasswordData.toJS();
  const authenticatedUsername = authData.authenticatedUsername;
  const usernameParam = routing.usernameParam;
  const error = authData.errorFetchingLoginConfig;
  const isFormDisabled = authData.isFetchingLoginConfig;
  const notification = resetPasswordData.notification;

  return {
    error,
    usernameParam,
    authenticatedUsername,
    isFormDisabled,
    notification,
    agentLoginSwitchUsername
  };
};

export default connect(mapStateToProps, {
  agentLoginSwitchUsername
})(Component);
