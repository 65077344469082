import * as React from 'react';
import RequestResetForm from '../../containers/reset-2fa-container';

export interface Reset2faLandingProps {
  token: string;
  isUpdatingTotp: boolean;
  resetTotp: (args?: { token?: string }) => void;
  toggleWorkingScreen: (show: boolean) => void;
}

class Reset2faLanding extends React.Component<Reset2faLandingProps, {}> {
  componentDidMount(): void {
    const { token, resetTotp, isUpdatingTotp } = this.props;
    if (!isUpdatingTotp) {
      resetTotp({ token });
    }
  }

  render() {
    const { isUpdatingTotp } = this.props;
    return <RequestResetForm isDisabled={isUpdatingTotp} />;
  }
}

export default Reset2faLanding;
