import * as React from 'react';
import NoMessage from './no-message';
import ErrorWrapper from './error-wrapper';

interface FormValidationMessageProps {
  error: string | null;
  dataTest?: string;
}

const FormValidationMessage = (props: FormValidationMessageProps): JSX.Element => {
  if (props.error) {
    return <ErrorWrapper error={props.error} dataTest={props.dataTest} />;
  }
  return <NoMessage />;
};

export default FormValidationMessage;
