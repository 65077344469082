import * as React from 'react';
import RedirectSMB from './redirect-link-smb';
import RedirectCrashPlan from './redirect-link-crashplan';

const RedirectLinks = () => {
  return (
    <div>
      <RedirectCrashPlan />
      <RedirectSMB />
    </div>
  );
};

export default RedirectLinks;
