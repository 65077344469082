import { LoginPageType } from '../constants/types';

export const getLoginPageType = (hostname = window?.location?.hostname) => {
  if (hostname.endsWith(window.__SMB_ZONE_NAME) && window.__SMB_ZONE_NAME) {
    return LoginPageType.SMB;
  }

  return LoginPageType.CRASHPLAN;
};

export default getLoginPageType;
