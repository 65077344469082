import React from 'react';
import SvgIcon from './svg-icon';

class CheckMarkIcon extends React.Component {
  static defaultProps = {
    width: 16,
    height: 16
  };

  render() {
    return (
      <SvgIcon className="c42-check-mark-icon" {...this.props}>
        <g><path d="M5 10.9 1.3 7.2 2.7 5.8 5 8.1 11.3 1.8 12.7 3.2z"/></g>
      </SvgIcon>
    );
  }
}

module.exports = CheckMarkIcon;
