import PropTypes from 'prop-types';
import React from 'react';
import createReactClass from 'create-react-class';
import Classable from '../mixins/classable';
import DateTime from '../utils/date-time';
import CalendarMonth from './calendar-month';
import CalendarToolbar from './calendar-toolbar';
import TimePicker from './time-picker';
import SlideInTransitionGroup from '../transition-groups/slide-in';
import FlatButton from '../flat-button';
import Locale from '../locales/en';
import _noop from 'lodash/noop';
import _omit from 'lodash/omit';

const TRANSITION_TIMEOUT = 2000;

const Calendar = createReactClass({
  displayName: 'Calendar',
  mixins: [Classable],

  propTypes: {
    initialDate: PropTypes.object,
    isActive: PropTypes.bool,
    timePicker: PropTypes.object,
    calendarCurrentButtonActionStartOfDay: PropTypes.bool, // Whether to specify the start or end of a day when button is clicked?
    currentButton: PropTypes.object,
    days: PropTypes.array,
    months: PropTypes.array,
    weekStart: PropTypes.number,
    meridiem: PropTypes.array,
    futureDisabled: PropTypes.bool,
    onChange: PropTypes.func,
  },

  getDefaultProps: function() {
    return {
      initialDate: DateTime.now(),
      timePicker: {},
      currentButton: {},
      days: Locale.days,
      months: Locale.months,
      weekStart: Locale.weekStart,
      meridiem: Locale.meridiem,
      onChange: _noop,
    };
  },

  getInitialState: function() {
    return {
      displayDate: DateTime.getFirstDayOfMonth(this.props.initialDate),
      selectedDate: this.props.initialDate,
      transitionHackKey: 0,
      transitionDirection: 'left'
    };
  },

  componentWillReceiveProps: function(nextProps) {
    if (nextProps.initialDate !== this.props.initialDate) {
      var d = nextProps.initialDate || DateTime.now();
      this.setState({
        displayDate: DateTime.getFirstDayOfMonth(d),
        selectedDate: d,
        remountTimeout: this.remountTimeout(nextProps.initialDate, this.props.initialDate)
      });
    }
  },

  componentWillUnmount: function() {
    clearTimeout(this.state.remountTimeout);
  },

  // Some browsers see flakiness with this component's SlideInTransitionGroup
  // transitions. So, whenever we transition to a new month, we must add a timeout
  // to remount the SlideInTransitionGroup component in case of animation errors.
  // See issue: https://github.com/facebook/react/issues/1326
  remountTimeout: function(date1, date2){
    var {remountTimeout, transitionHackKey} = this.state;

    if(date1.getMonth() === date2.getMonth()){
      // don't need to remount if months are the same
      return remountTimeout;
    }

    clearTimeout(remountTimeout);

    return setTimeout(()=>{
      this.setState({
        transitionHackKey: ++transitionHackKey,
        remountTimeout: clearTimeout(remountTimeout),
      });
    }, TRANSITION_TIMEOUT);
  },

  render: function() {
    var {
      displayDate,
      transitionHackKey,
      transitionDirection,
      selectedDate,
    } = this.state;
    var otherProps = this._otherProps();
    var currentButtonProps = this._currentButtonProps();
    var timePickerProps = this._timePickerProps();
    var weekCount = DateTime.getWeekArray(displayDate, this.props.weekStart).length;
    var classes = this.getClasses('mui-date-picker-calendar', {
      'mui-is-4week': weekCount === 4,
      'mui-is-5week': weekCount === 5,
      'mui-is-6week': weekCount === 6
    });

    return (
      <div className={classes} {...otherProps}>

        <div
          key={transitionHackKey}
          className="mui-date-picker-calendar-container">
          <CalendarToolbar
            months={this.props.months}
            displayDate={displayDate}
            onLeftClick={this._handleLeftClick}
            onRightClick={this._handleRightClick} />

          {this._weekTitleList()}

          <div className="mui-date-picker-calendar-week-title-spacer" />

          <SlideInTransitionGroup
            direction={transitionDirection}>
            <CalendarMonth
              key={displayDate.toDateString()}
              futureDisabled={this.props.futureDisabled}
              displayDate={displayDate}
              onDayClick={this._handleDayClick}
              selectedDate={selectedDate}
              weekStart={this.props.weekStart} />
          </SlideInTransitionGroup>

          <div className="mui-date-picker-calendar-time-picker">
            <TimePicker {...timePickerProps} />
            <FlatButton {...currentButtonProps} />
          </div>
        </div>
      </div>
    );
  },

  getSelectedDate: function() {
    return this.state.selectedDate;
  },

  _otherProps: function() {
    return _omit(this.props, [
      'initialDate',
      'isActive',
      'timePicker',
      'currentButton',
      'days',
      'months',
      'weekStart',
      'meridiem',
      'futureDisabled',
      'calendarCurrentButtonActionStartOfDay',
    ]);
  },

  _currentButtonProps: function() {
    const otherProps = _omit(this.props.currentButton, [
      'label',
      'className',
      'linkButton',
      'onClick'
    ]);

    return {
      ...otherProps, 
      label: this.props.currentButton.label || Locale.mostCurrent,
      className: 'mui-date-picker-calendar-current-button',
      linkButton: true,
      onClick: this._handleCurrentClick,
      'data-test': 'c42ui-calendar-most-current-button',
    };
  },

  _timePickerProps: function() {
    const otherProps = _omit(this.props.timePicker, [
      'initialTime',
      'onChange'
    ]);

    return {
      ...otherProps,
      initialTime: this.state.selectedDate,
      onChange: this._handleTimeChange,
      meridiem: this.props.meridiem,
    };
  },

  _weekTitleList: function(){
    var dayNames = this.props.days;
    var weekStart = this.props.weekStart;

    var dayIndex;
    var titles = this.props.days.map(function(d, i){
      dayIndex = (i + weekStart) % 7;
      return <li key={dayIndex} className="mui-date-picker-calendar-week-title-day">{dayNames[dayIndex]}</li>;
    });

    return (
      <ul className="mui-date-picker-calendar-week-title" data-test="c42ui-calendar-week-title">
        {titles}
      </ul>
    );
  },

  _handleTimeChange: function(selectedDate){
    this._setSelectedDate(selectedDate, true);
  },

  _addDisplayDate: function(m) {
    var newDisplayDate = DateTime.clone(this.state.displayDate);
    newDisplayDate.setMonth(newDisplayDate.getMonth() + m);
    this._setDisplayDate(newDisplayDate);
  },

  _setDisplayDate: function(d, newSelectedDate, cb) {
    cb = cb || _noop;
    var newDisplayDate = DateTime.getFirstDayOfMonth(d);
    var direction = newDisplayDate > this.state.displayDate ? 'left' : 'right';

    if (newDisplayDate !== this.state.displayDate) {
      this.setState({
        displayDate: newDisplayDate,
        transitionDirection: direction,
        remountTimeout: this.remountTimeout(newDisplayDate, this.state.displayDate),
        selectedDate: newSelectedDate || this.state.selectedDate
      }, cb);
    }
  },

  _setSelectedDate: function(d, overrideCurrentTime) {
    var newDisplayDate = DateTime.getFirstDayOfMonth(d);
    var currentDate = this.state.selectedDate;

    var onChange = () => {
      this.props.onChange(d);
    };

    if(!overrideCurrentTime){
      // sync the time with the previous selection when choosing a new date
      d.setHours(currentDate.getHours());
      d.setMinutes(currentDate.getMinutes());
    }

    if (newDisplayDate !== this.state.displayDate) {
      this._setDisplayDate(newDisplayDate, d, () => onChange());
    } else {
      this.setState({
        selectedDate: d
      }, () => onChange());
    }
  },

  _handleCurrentClick: function(){
    let dateToSet;
    if (this.props.calendarCurrentButtonActionStartOfDay) {
      dateToSet = DateTime.startOfDay();
    } else {
      dateToSet = DateTime.endOfDay();
    }
    this._setSelectedDate(dateToSet, true);
  },

  _handleDayClick: function(e, date) {
    this._setSelectedDate(date);
  },

  _handleLeftClick: function() {
    this._addDisplayDate(-1);
  },

  _handleRightClick: function() {
    this._addDisplayDate(1);
  },
});

module.exports = Calendar;
