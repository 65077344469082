import * as Immutable from 'immutable';
import { LOCATION_CHANGE, push } from 'react-router-redux';
import Redux from 'redux-thunk';
import CoreClient from '@cpg/core-client';
import Text from '../../shared/Text';
import C42Jwt from '@cpg/jwt';
import StoreInterface, { AuthData } from '../store-interface';
import { inputValueDispatcher } from '../utils/input-handlers';
import { request, POST, RequestResponse } from '../utils/request';

import { TOTP_CONFIG_REQUIRED } from '../constants/errors';

import { DEFAULT_REDIRECT_AFTER_LOGIN, LOGIN_INDEX, SSO_INDEX } from '../constants/routes';
import { MATCH_ALL_BACKSLASHES } from '../constants/regexes';
import {SET_MAIN_INITIALIZED, toggleWorkingScreen} from "./main-data";

const noop = () => {
  /* no op */
};

export const c42Jwt = new C42Jwt();

//////////////////////////////////////////
// API Paths
//////////////////////////////////////////

export const API_AUTHORITY_LOGIN_CONFIG_PATH = '/api/v3/LoginConfiguration';
export const API_TOTP_CREATE_SECRET_KEY_PATH = '/api/v9/totp-auth-factor/create-secret-key';
export const API_TOTP_COMPLETE_CONFIG_PATH = '/api/v9/totp-auth-factor/complete-configuration';

//////////////////////////////////////////
// Permissions
//////////////////////////////////////////

export const CONSOLE_LOGIN_PERMISSIONS = ['admin.console.login', 'console.login'];
export const CONSOLE_LOGIN_PERMISSIONS_ERROR = 'NoConsoleLoginPermission';

//////////////////////////////////////////
// Async Actions
//////////////////////////////////////////

export const AUTHENTICATE = 'login/auth-data/AUTHENTICATE';
export const AUTHENTICATE_ERROR = 'login/auth-data/AUTHENTICATE_ERROR';
export const AUTHENTICATE_RESPONSE = 'login/auth-data/AUTHENTICATE_RESPONSE';

export const COMPLETE_TOTP_CONFIG = 'login/auth-data/COMPLETE_TOTP_CONFIG';
export const COMPLETE_TOTP_CONFIG_ERROR = 'login/auth-data/COMPLETE_TOTP_CONFIG_ERROR';
export const COMPLETE_TOTP_CONFIG_RESPONSE = 'login/auth-data/COMPLETE_TOTP_CONFIG_RESPONSE';

export const DEAUTHENTICATE = 'login/auth-data/DEAUTHENTICATE';
export const DEAUTHENTICATE_RESPONSE = 'login/auth-data/DEAUTHENTICATE_RESPONSE';

export const FETCH_LOGIN_CONFIG = 'login/auth-data/FETCH_LOGIN_CONFIG';
export const FETCH_LOGIN_CONFIG_ERROR = 'login/auth-data/FETCH_LOGIN_CONFIG_ERROR';
export const FETCH_LOGIN_CONFIG_RESPONSE = 'login/auth-data/FETCH_LOGIN_CONFIG_RESPONSE';

export const FETCH_TOTP_SECRET = 'login/auth-data/FETCH_TOTP_SECRET';
export const FETCH_TOTP_SECRET_ERROR = 'login/auth-data/FETCH_TOTP_SECRET_ERROR';
export const FETCH_TOTP_SECRET_RESPONSE = 'login/auth-data/FETCH_TOTP_SECRET_RESPONSE';

export const FETCH_SERVER_DATA_RESPONSE = 'login/auth-data/FETCH_SERVER_DATA_RESPONSE';
//////////////////////////////////////////
// Form Input Actions
//////////////////////////////////////////

export const CHANGE_USERNAME = 'login/auth-data/CHANGE_USERNAME';
export const CHANGE_PASSWORD = 'login/auth-data/CHANGE_PASSWORD';
export const CHANGE_TOTP = 'login/auth-data/CHANGE_TOTP_CODE';

//////////////////////////////////////////
// Navigation Actions
//////////////////////////////////////////

export const GO_TO_STEP_USERNAME = 'login/auth-data/GO_TO_STEP_USERNAME';
export const GO_TO_STEP_PASSWORD = 'login/auth-data/GO_TO_STEP_PASSWORD';
export const GO_TO_STEP_TOTP_CODE = 'login/auth-data/GO_TO_STEP_TOTP_CODE';
export const GO_TO_STEP_TOTP_INVALID = 'login/auth-data/GO_TO_STEP_TOTP_INVALID';
export const GO_TO_STEP_TOTP_CONFIG = 'login/auth-data/GO_TO_STEP_TOTP_CONFIG';
export const GO_TO_STEP_TOTP_SN_WARNING = 'login/auth-data/GO_TO_STEP_TOTP_SN_WARNING';
export const GO_TO_USERNAME_MISMATCH = 'login/auth-data/GO_TO_USERNAME_MISMATCH';
export const GO_TO_AGENT_LOGIN_ERROR = 'login/auth-data/GO_TO_AGENT_LOGIN_ERROR';

export const STEP_USERNAME = 'username';
export const STEP_PASSWORD = 'password';
export const STEP_TOTP_CODE = 'totp-code';
export const STEP_TOTP_CONFIG = 'totp-config';
export const STEP_TOTP_INVALID = 'totp-invalid';
export const STEP_TOTP_SN_WARNING = 'totp-sn-warning';
export const STEP_USERNAME_MISMATCH = 'username-mismatch';
export const STEP_AGENT_LOGIN_ERROR = 'agent-login-error';

//////////////////////////////////////////
// Initial State
//////////////////////////////////////////

const initialDataValues = {
  adminEmail: null,
  adminPassword: null,
  adminPasswordConfirm: null,
  adminUsername: null,
  authenticatedUsername: null,
  loginConfigError: null,
  loginError: null,
  password: null,
  totp: null,
  totpSecret: null,
  username: null,
};

const initialErrors = {
  errorAuthenticating: null,
  errorFetchingLoginConfig: null,
};

const initialFormState = {
  currentStep: STEP_USERNAME,
  isAuthenticating: false,
  isDeauthenticating: false,
  isFetchingLoginConfig: false,
  isFetchingRedirect: false,
  isFetchingTotpSecret: false,
  isSSO: false,
  isUsingTotp: false,
  isTotpInvalid: false,
  isValidatingTotp: false,
};

const initialServerEnv = {
  clusterType: {} as any,
  serverType: {} as any,
};

export const initialState = Immutable.fromJS({
  ...initialFormState,
  ...initialDataValues,
  ...initialErrors,
  ...initialServerEnv,
} as AuthData);

//////////////////////////////////////////
// Reducer
//////////////////////////////////////////

export default (state = initialState, action) => {
  switch (action.type) {
    case FETCH_SERVER_DATA_RESPONSE:
      return state.merge({
        clusterType: action.clusterType,
        serverType: action.serverType,
      });
    case LOCATION_CHANGE:
      if (action.payload && action.payload.pathname === SSO_INDEX) {
        return state;
      }
      return state.merge(initialDataValues);
    case AUTHENTICATE:
      return state.merge({
        isAuthenticating: true,
        errorAuthenticating: null,
        loginError: null,
      });
    case AUTHENTICATE_ERROR:
      return state.merge({
        isAuthenticating: false,
        errorAuthenticating: action.loginError,
        loginError: action.loginError,
      });
    case AUTHENTICATE_RESPONSE:
      return state.merge({
        isAuthenticating: false,
      });

    case COMPLETE_TOTP_CONFIG:
      return state.merge({
        isTotpInvalid: false,
        isValidatingTotp: true,
      });
    case COMPLETE_TOTP_CONFIG_ERROR:
      return state.merge({
        isTotpInvalid: true,
        isValidatingTotp: false,
      });
    case COMPLETE_TOTP_CONFIG_RESPONSE:
      return state.merge({
        isValidatingTotp: false,
      });

    case DEAUTHENTICATE:
      return state.merge({
        isDeauthenticating: true,
        authenticatedUsername: null,
      });
    case DEAUTHENTICATE_RESPONSE:
      return state.merge({
        isDeauthenticating: false,
      });

    case FETCH_LOGIN_CONFIG:
      return state.merge({
        isFetchingLoginConfig: true,
        errorFetchingLoginConfig: null,
      });
    case FETCH_LOGIN_CONFIG_ERROR:
      return state.merge({
        isFetchingLoginConfig: false,
        errorFetchingLoginConfig: action.error,
      });
    case FETCH_LOGIN_CONFIG_RESPONSE:
      return state.merge({
        isFetchingLoginConfig: false,
        isSSO: action.isSSO,
        isUsingTotp: action.isUsingTotp,
        regKey: action.regKey,
      });

    case FETCH_TOTP_SECRET:
      return state.merge({
        errorAuthenticating: false,
        isAuthenticating: true,
      });
    case FETCH_TOTP_SECRET_ERROR:
      return state.merge({
        isAuthenticating: false,
      });
    case FETCH_TOTP_SECRET_RESPONSE:
      return state.merge({
        isAuthenticating: false,
        totpSecret: action.totpSecret,
      });

    /////////////////////
    // Wizard Steps
    /////////////////////

    case GO_TO_STEP_USERNAME:
      return state.merge(
        {
          currentStep: STEP_USERNAME,
        },
        initialState,
      );
    case GO_TO_STEP_PASSWORD:
      return state.merge({
        currentStep: STEP_PASSWORD,
      });
    case GO_TO_STEP_TOTP_CODE:
      return state.merge({
        currentStep: STEP_TOTP_CODE,
      });
    case GO_TO_STEP_TOTP_INVALID:
      return state.merge({
        currentStep: STEP_TOTP_INVALID,
      });
    case GO_TO_STEP_TOTP_CONFIG:
      return state.merge({
        currentStep: STEP_TOTP_CONFIG,
      });
    case GO_TO_STEP_TOTP_SN_WARNING:
      return state.merge({
        currentStep: STEP_TOTP_SN_WARNING,
      });
    case GO_TO_USERNAME_MISMATCH:
      return state.merge({
        currentStep: STEP_USERNAME_MISMATCH,
        authenticatedUsername: action.payload.authenticatedUsername,
      });
    case GO_TO_AGENT_LOGIN_ERROR:
      return state.merge({
        currentStep: STEP_AGENT_LOGIN_ERROR,
        loginError: action.payload.loginError,
      });

    /////////////////////
    // Form Input Values
    /////////////////////
    case CHANGE_PASSWORD:
      return state.merge({
        password: action.password,
      });
    case CHANGE_TOTP:
      return state.merge({
        totp: action.totp,
      });
    case CHANGE_USERNAME:
      return state.merge({
        username: action.username,
      });

    default:
      return state;
  }
};

//////////////////////////////////////////
// Form Input Actions
//////////////////////////////////////////
export const onChangePassword: Redux.ThunkAction<void, StoreInterface, void> = inputValueDispatcher.bind(
  null,
  CHANGE_PASSWORD,
  'password',
);
export const onChangeTotp: Redux.ThunkAction<void, StoreInterface, void> = inputValueDispatcher.bind(
  null,
  CHANGE_TOTP,
  'totp',
);
export const onChangeUsername: Redux.ThunkAction<void, StoreInterface, void> = inputValueDispatcher.bind(
  null,
  CHANGE_USERNAME,
  'username',
);

//////////////////////////////////////////
// Authenticate Actions
//////////////////////////////////////////

export function authenticate(opts?: any): Redux.ThunkAction<Promise<any>, StoreInterface, void> {
  return (dispatch) => {
    let promise;
    dispatch({ type: AUTHENTICATE });

    if (opts == null) {
      promise = c42Jwt.verifyAuthenticated();
    } else if (opts.loginToken) {
      promise = c42Jwt.loginWithLoginToken(opts.loginToken);
    } else if (opts.isLogin && opts.username && opts.password) {
      promise = c42Jwt.login(opts.username, opts.password, opts.totp || '');
    } else {
      promise = Promise.reject('Unsupported scenario encountered, opts: ' + JSON.stringify(opts));
    }

    return promise.catch((error) => {
      dispatch({ type: AUTHENTICATE_ERROR });
      return Promise.reject(error);
    });
  };
}

export function checkLoginPermission(): Promise<any> {
  return CoreClient.Permission.find()
    .then((permissions = []) => {
      if (Array.isArray(permissions)) {
        const hasPermission = permissions.reduce((acc, perm = {}) => {
          return CONSOLE_LOGIN_PERMISSIONS.indexOf(perm.value) > -1 ? true : acc;
        }, false);
        if (hasPermission) {
          return true;
        }
      }
      throw new Error();
    })
    .catch(() => {
      return Promise.reject(CONSOLE_LOGIN_PERMISSIONS_ERROR);
    });
}

export function agentLoginSwitchUsername(): Redux.ThunkAction<Promise<any>, StoreInterface, void> {
  return (dispatch) => {
    dispatch({ type: DEAUTHENTICATE });
    return c42Jwt.logout().finally(() => {
      dispatch({ type: DEAUTHENTICATE_RESPONSE });
      dispatch({ type: GO_TO_STEP_USERNAME });
    });
  };
}

export function deauthenticate(): Redux.ThunkAction<Promise<any>, StoreInterface, void> {
  return (dispatch) => {
    dispatch({ type: DEAUTHENTICATE });
    return c42Jwt.logout().finally(() => dispatch(afterDeauthenticate()));
  };
}

function afterDeauthenticate(): Redux.ThunkAction<void, StoreInterface, void> {
  return (dispatch) => {
    dispatch({ type: DEAUTHENTICATE_RESPONSE });
    dispatch(push(LOGIN_INDEX as any));
  };
}

//////////////////////////////////////////
// Submit Login Actions
//////////////////////////////////////////

export function submitLogin(): Redux.ThunkAction<Promise<any>, StoreInterface, void> {
  return (dispatch, getState) => {
    const state: AuthData = getState().authData.toJS();
    const { username, password, totp } = state;

    return dispatch(
      authenticate({
        isLogin: true,
        username,
        password,
        totp,
      }),
    )
      .then(
        () => dispatch(onSubmitLoginSuccess()),
        (err) => dispatch(onSubmitLoginError(err as JQueryXHR)),
      );
  };
}

export function onSubmitLoginSuccess(): Redux.ThunkAction<void, StoreInterface, void> {
  return (dispatch, getState) => {
    let redirectPath;
    const state: StoreInterface = getState();
    const routing = state.routing.toJS();
    dispatch({ type: AUTHENTICATE_RESPONSE });

    // This param indicates universal agent login
    if (routing.uuidParam) {
      // Warn the user if they are already authenticated as someone else
      const authenticatedUsername = c42Jwt.extractClaimsFromJwt().sub;
      const attemptedUsername = routing.usernameParam;
      if (authenticatedUsername !== attemptedUsername) {
        dispatch({type: GO_TO_USERNAME_MISMATCH, payload: {authenticatedUsername}})
        dispatch({type: SET_MAIN_INITIALIZED})
        return Promise.resolve();
      }
      return request("/api/v3/agent-login-finalize", POST, {
        body: JSON.stringify({
          uuid: routing.uuidParam,
        }),
        headers: {
          'content-type': 'application/json',
        },
      })
        .then(() => {
          redirectPath = "/static/ssoAuthLoginSuccess_cp3.html";
          window.location.replace(redirectPath);
        })
        .catch((response) => {
          // 400 error -- could not find SsoAuthUser with UUID -- possible time out -- try again
          // 401 error -- some sort of general error -- try again
          // 500 error -- Error sending SsoAuth finalize -- possible time out -- try again
          dispatch({type: GO_TO_AGENT_LOGIN_ERROR, payload: {loginError: response.status}});
          dispatch({type: SET_MAIN_INITIALIZED});
          return Promise.resolve();
        });
    }

    return checkLoginPermission().then(() => {
      if (routing.redirectParam) {
        /**
         * Plus signs change their meaning when encoded, decoded, and then parsed as a URL param.
         * Since the redirectParam is a URI encoded path that may include its own params, we
         * need to re-encode the plus signs so they don't get converted into spaces.
         */
        redirectPath = decodeURIComponent(routing.redirectParam).replace(/\+/g, '%2B');
      } else {
        redirectPath = DEFAULT_REDIRECT_AFTER_LOGIN;
      }
      // Remove any potential backslashes from the redirectPath (PL-105140)
      redirectPath = redirectPath.replace(MATCH_ALL_BACKSLASHES, '');

      window.location.replace(redirectPath);
      return Promise.resolve();
    }).catch((err) => {
      switch (err.toString()) {
        case CONSOLE_LOGIN_PERMISSIONS_ERROR:
          return dispatch({
            type: AUTHENTICATE_ERROR,
            loginError: Text.get('login_error_auth'),
          });
      }
    });
  };
}

function onSubmitLoginError(err: JQueryXHR): Redux.ThunkAction<void, StoreInterface, void> {
  return (dispatch) => {
    c42Jwt.logout();

    // ServerEnv is reloaded on auth error to reload challenge from server.
    return dispatch(internals.getServerEnv()).then(({ serverEnv }) => {
      const isStorageNode = serverEnv?.serverType?.storage;
      switch (err.toString()) {
        case TOTP_CONFIG_REQUIRED:
          return isStorageNode ? dispatch(gotoStepTotpStorageNodeWarning()) : dispatch(gotoStepTotpConfig());

        default:
          return dispatch({
            type: AUTHENTICATE_ERROR,
            loginError: Text.get('login_error_incorrect'),
          });
      }
    });
  };
}

//////////////////////////////////////////
// Fetch Login Config Actions
//////////////////////////////////////////

export function fetchLoginConfig(): Redux.ThunkAction<Promise<any>, StoreInterface, void> {
  return (dispatch, getState) => {
    dispatch({ type: FETCH_LOGIN_CONFIG });

    const state: AuthData = getState().authData.toJS();

    const {
      location: { protocol, host },
    } = window;
    const SERVER_ADDRESS = `${protocol}//${host}`;

    const params = {
      query: {
        url: SERVER_ADDRESS,
        username: (state.username) ? state.username.trim() : "",
      },
      options: {
        url: `${API_AUTHORITY_LOGIN_CONFIG_PATH}`,
      },
    };

    return CoreClient.LoginConfiguration.find(params)
      .then((responseData) => {
        dispatch(onFetchLoginConfigSuccess(responseData));
      })
      .catch((error) => {
        dispatch({
          error: Text.get('login_config_error_generic'),
          type: FETCH_LOGIN_CONFIG_ERROR,
        });
        return Promise.reject(error);
      });
  };
}

interface FetchLoginConfigResponse {
  loginType: string;
  regKey: string;
}

function onFetchLoginConfigSuccess(
  responseData: FetchLoginConfigResponse,
): Redux.ThunkAction<void, StoreInterface, void> {
  return (dispatch) => {
    const { loginType, regKey } = responseData;

    const type = String(loginType).toLowerCase();

    // These login type strings are derived from Core:
    // https://github.com/CrashPlanGroup/authority/blob/main/pro_core/src/impl/java/com/code42/login/LoginType.java
    const ssoTypes = ['private_sso', 'cloud_sso', 'saml_sso'];
    const isSSO = ssoTypes.indexOf(type) > -1;
    const isUsingTotp = type === 'local_2fa';

    dispatch({
      isSSO,
      isUsingTotp,
      regKey,
      type: FETCH_LOGIN_CONFIG_RESPONSE,
    });
  };
}

//////////////////////////////////////////
// TOTP Fetch Secret Actions
//////////////////////////////////////////

export function fetchTotpSecret(): Redux.ThunkAction<Promise<any>, StoreInterface, void> {
  return (dispatch, getState) => {
    const state: AuthData = getState().authData.toJS();
    const { username, password } = state;
    dispatch({ type: FETCH_TOTP_SECRET });

    return request(API_TOTP_CREATE_SECRET_KEY_PATH, POST, {
      body: JSON.stringify({
        username,
        password,
      }),
      headers: {
        'content-type': 'application/json',
      },
    })
      .then((response) => {
        return dispatch(onFetchTotpSecretSuccess(response));
      })
      .catch(() => {
        return dispatch(onFetchTotpSecretError());
      });
  };
}

export function onFetchTotpSecretSuccess(response: RequestResponse) {
  return (dispatch) => {
    const { body } = response;
    if (!body || !body.data || !body.data.secretKey) {
      return Promise.reject('NO_SECRET_KEY');
    }
    dispatch({
      totpSecret: body.data.secretKey,
      type: FETCH_TOTP_SECRET_RESPONSE,
    });
  };
}

export function onFetchTotpSecretError() {
  return (dispatch) => {
    dispatch({
      type: FETCH_TOTP_SECRET_ERROR,
    });
    dispatch({
      type: AUTHENTICATE_ERROR,
      loginError: Text.get('login_error_incorrect'),
    });
  };
}

//////////////////////////////////////////
// TOTP Complete Config Actions
//////////////////////////////////////////

export function completeTotpConfig(): Redux.ThunkAction<Promise<any>, StoreInterface, void> {
  return (dispatch, getState) => {
    const state: AuthData = getState().authData.toJS();
    const { username, password, totp } = state;
    dispatch({ type: COMPLETE_TOTP_CONFIG });

    return request(API_TOTP_COMPLETE_CONFIG_PATH, POST, {
      body: JSON.stringify({
        username,
        password,
        totp: Number(totp),
      }),
      headers: {
        'content-type': 'application/json',
      },
    })
      .then(() => {
        return dispatch(onCompleteTotpConfigSuccess());
      })
      .catch(() => {
        return dispatch(onCompleteTotpConfigError());
      });
  };
}

export function onCompleteTotpConfigSuccess() {
  return (dispatch) => {
    return dispatch({
      type: COMPLETE_TOTP_CONFIG_RESPONSE,
    });
  };
}

export function onCompleteTotpConfigError() {
  return (dispatch) => {
    return Promise.reject(
      dispatch({
        type: COMPLETE_TOTP_CONFIG_ERROR,
      }),
    );
  };
}

//////////////////////////////////////////
// Global Form Actions
//////////////////////////////////////////

export function resetForm() {
  return (dispatch) => {
    dispatch(push(LOGIN_INDEX as any));
    dispatch(gotoStepUsername());
  };
}

export function gotoSSO() {
  return (dispatch) => {
    dispatch(push(SSO_INDEX as any));
  };
}

//////////////////////////////////////////
// Username Actions
//////////////////////////////////////////

export function gotoStepUsername() {
  return (dispatch) => {
    dispatch({ type: GO_TO_STEP_USERNAME });
  };
}

export function submitUsername() {
  return (dispatch, getState) => {
    dispatch(fetchLoginConfig()).then(() => {
      const { isSSO } = getState().authData.toJS();
      if (isSSO) {
        return dispatch(gotoSSO());
      }
      dispatch(gotoStepPassword());
    }, noop);
  };
}

//////////////////////////////////////////
// Password Actions
//////////////////////////////////////////

export function gotoStepPassword() {
  return (dispatch) => {
    dispatch({ type: GO_TO_STEP_PASSWORD });
  };
}

//////////////////////////////////////////
// TOTP Code Actions
//////////////////////////////////////////

export function gotoStepTotpCode() {
  return (dispatch) => {
    dispatch({ type: GO_TO_STEP_TOTP_CODE });
  };
}

export function gotoStepTotpInvalid() {
  return (dispatch) => {
    dispatch({ type: GO_TO_STEP_TOTP_INVALID });
  };
}

//////////////////////////////////////////
// TOTP Config Actions
//////////////////////////////////////////

export function gotoStepTotpConfig() {
  return (dispatch) => {
    return dispatch(fetchTotpSecret()).then(() => {
      dispatch({ type: GO_TO_STEP_TOTP_CONFIG });
    });
  };
}

export function submitTotpConfig() {
  return (dispatch) => {
    return dispatch(completeTotpConfig())
      .then(() => {
        dispatch(submitLogin());
      })
      .catch(noop);
  };
}

//////////////////////////////////////////
// TOTP Storage Node Warning Actions
//////////////////////////////////////////

export function gotoStepTotpStorageNodeWarning() {
  return (dispatch) => {
    return dispatch({ type: GO_TO_STEP_TOTP_SN_WARNING });
  };
}

//////////////////////////////////////////
// Fetch Server Data Actions
//////////////////////////////////////////
export function getServerEnv(): Redux.ThunkAction<Promise<any>, StoreInterface, void> {
  return (dispatch) => {
    return CoreClient.ServerEnv.find().then((serverEnv) => {
      c42Jwt.register(serverEnv);
      const { clusterType, serverType } = serverEnv;

      dispatch({
        type: FETCH_SERVER_DATA_RESPONSE,
        clusterType,
        serverType,
      });
      return Promise.resolve({ serverEnv });
    });
  };
}

export const internals = {
  getServerEnv,
};
