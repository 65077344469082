import PropTypes from 'prop-types';
import React from 'react';
import createReactClass from 'create-react-class';
import { CSSTransitionGroup } from 'react-transition-group';
import Classable from '../mixins/classable';
import _omit from 'lodash/omit';

const SlideIn = createReactClass({
  displayName: 'SlideIn',
  mixins: [Classable],

  propTypes: {
    direction: PropTypes.oneOf(['left', 'right', 'up', 'down'])
  },

  getDefaultProps: function() {
    return {
      direction: 'left',
      transitionEnterTimeout: 500,
      transitionLeaveTimeout: 300
    };
  },

  render: function() {
    const transitionGroupProps = _omit(this.props, [
      'className',
      'direction'
    ]);

    var classes = this.getClasses('mui-transition-slide-in');

    classes += ' mui-is-' + this.props.direction;

    //Add a custom className to every child
    React.Children.map(this.props.children, function(child) {
      var classes = child.props.className ?
        child.props.className + ' mui-transition-slide-in-child':
        'mui-transition-slide-in-child';

      React.cloneElement(child, {
        className: classes
      });
    });

    return (
      <CSSTransitionGroup {...transitionGroupProps}
        className={classes}
        transitionName="mui-transition-slide-in"
        component="div">
        {this.props.children}
      </CSSTransitionGroup>
    );
  },
});

module.exports = SlideIn;
