import PropTypes from 'prop-types';
import React from 'react';
import createReactClass from 'create-react-class';
import KeyCode from './utils/key-code';
import Classable from './mixins/classable';
import WindowListenable from './mixins/window-listenable';
import FocusRipple from './ripples/focus-ripple';
import _omit from 'lodash/omit';

var EnhancedButton = createReactClass({
  displayName: 'EnhancedButton',
  mixins: [Classable, WindowListenable],

  propTypes: {
    centerRipple: PropTypes.bool,
    className: PropTypes.string,
    disabled: PropTypes.bool,
    disableFocusRipple: PropTypes.bool,
    linkButton: PropTypes.bool,
    onBlur: PropTypes.func,
    onFocus: PropTypes.func,
    onClick: PropTypes.func,
    type: PropTypes.string,
  },

  windowListeners: {
    'keydown': '_handleWindowKeydown',
    'keyup': '_handleWindowKeyup'
  },

  getInitialState: function() {
    return {
      isKeyboardFocused: false
    };
  },

  render: function() {
    const {
      disabled,
      disableFocusRipple,
      linkButton,
      ...other
    } = this.props;
    const childElementProps = _omit(other, [
      'centerRipple',
      'onBlur',
      'onFocus',
      'onClick',
      'type',
    ]);
    const classes = this.getClasses('mui-enhanced-button', {
      'mui-is-disabled': disabled,
      'mui-is-keyboard-focused': this.state.isKeyboardFocused,
      'mui-is-link-button': linkButton
    });
    const focusRipple = (
      <FocusRipple
        key="focusRipple"
        show={this.state.isKeyboardFocused} />
    );
    const buttonProps = {
      className: classes,
      disabled: disabled,
      onBlur: this._handleBlur,
      onFocus: this._handleFocus,
      onClick: this._handleClick
    };
    const buttonChildren = [
      this.props.children,
      disabled || disableFocusRipple ? null : focusRipple
    ];

    if (disabled && linkButton) {
      return (
        <span {...childElementProps}
          className={classes}
          disabled={disabled}>
          {this.props.children}
        </span>
      );
    }

    return linkButton ? (
      <a {...childElementProps} {...buttonProps}>
        {buttonChildren}
      </a>
    ) : (
      <button type="button" {...childElementProps} {...buttonProps}>
        {buttonChildren}
      </button>
    );
  },

  isKeyboardFocused: function() {
    return this.state.isKeyboardFocused;
  },

  _handleWindowKeydown: function(e) {
    if (e.keyCode == KeyCode.TAB) this._tabPressed = true;
    if (e.keyCode == KeyCode.ENTER && this.state.isKeyboardFocused) {
      this._handleClick(e);
    }
  },

  _handleWindowKeyup: function(e) {
    if (e.keyCode == KeyCode.SPACE && this.state.isKeyboardFocused) {
      this._handleClick(e);
    }
  },

  _handleBlur: function(e) {
    this.setState({
      isKeyboardFocused: false
    });

    if (this.props.onBlur) this.props.onBlur(e);
  },

  _handleFocus: function(e) {
    //setTimeout is needed becuase the focus event fires first
    //Wait so that we can capture if this was a keyboard focus
    //or touch focus
    setTimeout(function() {
      if (this._tabPressed) {
        this.setState({
          isKeyboardFocused: true
        });
      }
    }.bind(this), 150);

    if (this.props.onFocus) this.props.onFocus(e);
  },

  _handleClick: function(e) {
    this._tabPressed = false;
    this.setState({
      isKeyboardFocused: false
    });
    if (this.props.onClick) this.props.onClick(e);
  },
});

module.exports = EnhancedButton;
