/* eslint react/jsx-no-target-blank: 0 */
import * as React from 'react';
import Text from '../../../shared/Text';
import {
  LoginSetupTotpAddAccountDetails,
  LoginStepTotpCodeInputArea,
  LoginSetupTotpDetails,
} from './step-totp-details';

interface Props {
  onChangeTotp: () => void;
  submitForm: () => void;
  resetForm: () => void;
  totpKeyUri: string;
  totpSecret: string;
  isFormDisabled: boolean;
  isTotpInvalid: boolean;
}

interface State {
  isSecretVisible: boolean;
}

export default class LoginStepTotpConfig extends React.Component<Props, State> {
  constructor() {
    super();
    this.state = {
      isSecretVisible: true, // defaulting to show the secret
    };
  }

  submitForm = (e: React.FormEvent<any>) => {
    if (e && 'preventDefault' in e) {
      e.preventDefault();
    }
    if (typeof this.props.submitForm === 'function') {
      this.props.submitForm();
    }
  };

  resetForm = (e: React.MouseEvent<any>) => {
    if (e && 'preventDefault' in e) {
      e.preventDefault();
    }
    if (typeof this.props.resetForm === 'function') {
      this.props.resetForm();
    }
  };

  showSecret = (e: React.MouseEvent<any>) => {
    if (e && 'preventDefault' in e) {
      e.preventDefault();
    }
    this.setState({ isSecretVisible: true });
  };

  render() {
    const { onChangeTotp, isTotpInvalid, isFormDisabled, totpKeyUri, totpSecret } = this.props;

    const { isSecretVisible } = this.state;

    return (
      <form
        className="step-totp-config"
        data-test="login-form"
        name="loginFrm"
        noValidate
        onSubmit={this.submitForm}
      >
        <h1 className="totp-config-headline">{Text.get('login_totp_config_headline')}</h1>
        <p className="totp-config-description">{Text.get('login_totp_config_description')}</p>
        <ol className="totp-config-steps-list">
          <li>
            <h2 className="totp-config-headline">{Text.get('login_totp_config_step1_headline')}</h2>
            <LoginSetupTotpDetails />
          </li>
          <li>
            <h2 className="totp-config-headline">{Text.get('login_totp_config_step2_headline')}</h2>
            <LoginSetupTotpAddAccountDetails
              isSecretVisible={isSecretVisible}
              onShowSecretClick={this.showSecret}
              totpSecret={totpSecret}
              totpKeyUri={totpKeyUri}
            />
          </li>
          <li>
            <h2 className="totp-config-headline">{Text.get('login_totp_config_step3_headline')}</h2>
            <LoginStepTotpCodeInputArea
              isTotpInvalid={isTotpInvalid}
              isFormDisabled={isFormDisabled}
              onChangeTotp={onChangeTotp}
            />
          </li>
        </ol>
        <div className="form-control-wrapper submit">
          <input
            type="submit"
            value={Text.get('login_button_sign_in')}
            className="btn btn-default btn-flat btn-customizable cpg-blue c42-button"
            data-test="submit"
            disabled={isFormDisabled}
          />
        </div>
        <div className="form-control-wrapper link">
          <a href="#" onClick={this.resetForm} data-test="signin-different-account">
            {Text.get('sso_signin_different_account')}
          </a>
        </div>
      </form>
    );
  }
}
