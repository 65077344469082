const PropTypes = require('prop-types');
const React          = require('react');
const createReactClass = require('create-react-class');
const ReactDOM       = require('react-dom');
const Classable      = require('./mixins/classable');
const EnhancedButton = require('./enhanced-button');
const FontIcon       = require('./font-icon');
const Tooltip        = require('./tooltip');
const _omit          = require('lodash/omit');

const IconButton = createReactClass({
  displayName: 'IconButton',
  mixins: [Classable],

  propTypes: {
    className: PropTypes.string,
    disabled: PropTypes.bool,
    iconClassName: PropTypes.string,
    onBlur: PropTypes.func,
    onFocus: PropTypes.func,
    tooltip: PropTypes.string,
    touch: PropTypes.bool
  },

  componentDidMount: function() {
    if (this.props.tooltip) {
      this._positionTooltip();
    }
    if (process.NODE_ENV !== 'production') {
      if (this.props.iconClassName && this.props.children) {
        var warning = 'You have set both an iconClassName and a child icon. ' +
                      'It is recommended you use only one method when adding ' +
                      'icons to IconButtons.';
        console.warn(warning);
      }
    }
  },

  render: function() {
    const { touch } = this.props;
    const otherProps = _omit(this.props, [
      'tooltip',
      'touch',
      'iconClassName'
    ]);
    const classes = this.getClasses('mui-icon-button');
    let tooltip, fonticon;

    if (this.props.tooltip) {
      tooltip = (
        <Tooltip
          ref="tooltip"
          className="mui-icon-button-tooltip"
          touch={touch}>
            {this.props.tooltip}
        </Tooltip>
      );
    }

    if (this.props.iconClassName) {
      fonticon = (
        <FontIcon className={this.props.iconClassName}/>
      );
    }

    return (
      <EnhancedButton {...otherProps}
        ref="button"
        centerRipple
        className={classes}
        onBlur={this._handleBlur}
        onFocus={this._handleFocus}
        onMouseOut={this._handleMouseOut}
        onMouseOver={this._handleMouseOver}>

        {tooltip}
        {fonticon}
        {this.props.children}

      </EnhancedButton>
    );
  },

  _positionTooltip: function() {
    var tooltip = ReactDOM.findDOMNode(this.refs.tooltip);
    var tooltipWidth = tooltip.offsetWidth;
    var buttonWidth = 48;

    tooltip.style.left = (tooltipWidth - buttonWidth) / 2 * -1 + 'px';
  },

  _showTooltip: function(e) {
    if (!this.props.disabled && this.refs.tooltip) {
      this.refs.tooltip.showTooltip(e, null, true);
    }
  },

  _hideTooltip: function(e) {
    if (this.refs.tooltip) {
      this.refs.tooltip.hideTooltip(e, null, true);
    }
  },

  _handleBlur: function(e) {
    this._hideTooltip();
    if (this.props.onBlur) this.props.onBlur(e);
  },

  _handleFocus: function(e) {
    this._showTooltip();
    if (this.props.onFocus) this.props.onFocus(e);
  },

  _handleMouseOut: function(e) {
    if (!this.refs.button.isKeyboardFocused()) this._hideTooltip(e);
    if (this.props.onMouseOut) this.props.onMouseOut(e);
  },

  _handleMouseOver: function(e) {
    this._showTooltip(e);
    if (this.props.onMouseOver) this.props.onMouseOver(e);
  },
});

module.exports = IconButton;
