import * as React from 'react';
import Text from '../../shared/Text';
import FormValidationMessage from './form-validation-message';
import C42DropDownMenu from '@c42/ui/lib/c42-drop-down-menu';
import { LOGIN_INDEX } from '../constants/routes';

interface SsoProps {
  selectedProviderUid: string | null;
  regKey: string | null;
  username: string | null;
  onClickSubmit: (selectedProviderUid) => void;
  onChangeProvider: React.FormEventHandler<any>;
  fetchIdentityProviders: (regKey, username) => void;
  identityProviders: Array<{ ssoIdentityProviderUid: string; displayName: string }>;
  error: string | null;
}

export function submitHandler(props: SsoProps, e: React.FormEvent<any>) {
  e.preventDefault();
  const { selectedProviderUid, onClickSubmit } = props;
  onClickSubmit(selectedProviderUid);
}

export default class Sso extends React.Component<SsoProps, {}> {
  componentWillMount() {
    this.props.fetchIdentityProviders(this.props.regKey, this.props.username);
  }

  componentWillReceiveProps(nextProps: SsoProps) {
    const { identityProviders, onClickSubmit } = nextProps;

    // automatically select only identityProvider available
    if (identityProviders.length === 1) {
      onClickSubmit(identityProviders[0].ssoIdentityProviderUid);
    }
  }

  render() {
    const { identityProviders, error, selectedProviderUid, onChangeProvider } = this.props;

    // build menu items and find current selected index
    let selectedProviderIndex;
    const idpMenuItems = identityProviders.map((idp, i) => {
      const currentUid = idp.ssoIdentityProviderUid;
      if (currentUid === selectedProviderUid) {
        selectedProviderIndex = i;
      }
      return {
        payload: currentUid,
        text: idp.displayName,
      };
    });

    return (
      <form name="ssoFrm" noValidate>
        <FormValidationMessage error={error} />
        <div className="form-control-wrapper link">
          <span>{Text.get('sso_title')}</span>
        </div>
        <div className="form-control-wrapper">
          <C42DropDownMenu
            data-test="sso-form-provider-dropdown"
            placeholderLabel="Select provider"
            selectedIndex={selectedProviderIndex}
            onChange={onChangeProvider}
            menuItems={idpMenuItems}
          />
        </div>
        <div className="form-control-wrapper submit">
          <input
            type="submit"
            name="submit"
            value={Text.get('_button_continue')}
            className="btn btn-default btn-flat btn-customizable cpg-blue c42-button"
            onClick={submitHandler.bind(null, this.props)}
            disabled={selectedProviderUid == null}
          />
        </div>
        <div className="form-control-wrapper link">
          <a href={`#${LOGIN_INDEX}`}>{Text.get('sso_signin_different_account')}</a>
        </div>
      </form>
    );
  }
}
