import { connect } from 'react-redux';
import StoreInterface from '../../store-interface';
import Component from '../../components/login-form/step-totp-config';
import { encodeTotpKeyUri } from '../../utils/totp-key-uri-encoding';

import { onChangeTotp, resetForm, submitTotpConfig } from '../../modules/auth-data';

const mapStateToProps = (state: StoreInterface) => {
  const authData = state.authData.toJS();

  const totpKeyUri = encodeTotpKeyUri({
    account: authData.username,
    secret: authData.totpSecret,
  });

  return {
    totpKeyUri,
    totpSecret: authData.totpSecret,
    isFormDisabled: authData.isValidatingTotp || authData.isAuthenticating,
    isTotpInvalid: authData.isTotpInvalid,
  };
};

export default connect(mapStateToProps, {
  onChangeTotp,
  resetForm,
  submitForm: submitTotpConfig,
})(Component);
