import PropTypes from 'prop-types';
import React from 'react';
import createReactClass from 'create-react-class';
import Classable from './mixins/classable';
import _omit from 'lodash/omit';

const Paper = createReactClass({
  displayName: 'Paper',
  mixins: [Classable],

  propTypes: {
    circle: PropTypes.bool,
    innerClassName: PropTypes.string,
    innerStyle: PropTypes.object,
    rounded: PropTypes.bool,
    zDepth: PropTypes.oneOf([0,1,2,3,4,5])
  },

  getDefaultProps: function() {
    return {
      innerClassName: '',
      rounded: true,
      zDepth: 1
    };
  },

  render: function() {
    const divProps = _omit(this.props, [
        'className',
        'circle',
        'innerClassName',
        'rounded',
        'zDepth'
      ]),
      classes = this.getClasses(
        'mui-paper ' +
        'mui-z-depth-' + this.props.zDepth, {
        'mui-rounded': this.props.rounded,
        'mui-circle': this.props.circle
      }),
      insideClasses =
        this.props.innerClassName + ' ' +
        'mui-paper-container ' +
        'mui-z-depth-bottom';

    return (
      <div {...divProps} className={classes}>
        <div ref="innerContainer" className={insideClasses} style={this.props.innerStyle || {}}>
          {this.props.children}
        </div>
      </div>
    );
  },

  getInnerContainer: function() {
    return this.refs.innerContainer;
  },
});

module.exports = Paper;
