const PropTypes = require('prop-types');
var React = require('react');
var createReactClass = require('create-react-class');
var Classable = require('../mixins/classable');
var DateTime = require('../utils/date-time');
var FlatButton = require('../flat-button');
var OutlinedButton = require('../outlined-button');
var _omit = require('lodash/omit');

var DayButton = createReactClass({
  displayName: 'DayButton',
  mixins: [Classable],

  propTypes: {
    date: PropTypes.object,
    onClick: PropTypes.func,
    selected: PropTypes.bool,
    futureDisabled: PropTypes.bool,
  },

  render: function() {
    let ButtonType;
    let {
      date,
      selected,
      disabled,
      ...other
    } = this.props;

    const buttonTypeProps = _omit(other, [
      'className',
      'onClick',
      'primary',
      'futureDisabled',
      'label'
    ]);

    const classes = this.getClasses('mui-date-picker-day-button');
    disabled = this._isDisabled();

    if (selected) {
      ButtonType = FlatButton;
      buttonTypeProps['data-test'] = 'c42ui-selected-day-button';
    } else if (disabled) {
      ButtonType = OutlinedButton;
      buttonTypeProps['data-test'] = 'c42ui-disabled-day-button';
    } else {
      ButtonType = OutlinedButton;
      buttonTypeProps['data-test'] = 'c42ui-enabled-day-button';
    }

    if (date) {
      return (
        <ButtonType {...buttonTypeProps}
          primary={this._isTodayOrSelected()}
          disabled={disabled}
          label={date.getDate()}
          className={classes}
          onClick={this._handleClick} />
      );
    } else {
      return <span className={classes} />;
    }
  },

  _isTodayOrSelected: function(){
    return this.props.selected || !DateTime.isSameDay(this.props.date, DateTime.now());
  },

  _isDisabled: function(){
    return this.props.futureDisabled && DateTime.isLaterDate(this.props.date, DateTime.endOfDay());
  },

  _handleClick: function(e) {
    if (this.props.onClick) this.props.onClick(e, this.props.date);
  },
});

module.exports = DayButton;
