interface Args {
  account: string;
  secret: string;
  issuer?: string;
}

/**
 * Format a TOTP Secret into a Key URI for consumption by a TOTP generator,
 * such as Google Authenticator, Authy, Okta Verify, etc.
 *
 * https://github.com/google/google-authenticator/wiki/Key-Uri-Format
 *
 * @param {string} account
 * @param {string} secret
 * @param {string} [issuer]
 */
export function encodeTotpKeyUri({ account, secret, issuer = 'CrashPlan' }: Args) {
  return `otpauth://totp/${issuer}:${account}?secret=${secret}&issuer=${issuer}`;
}
