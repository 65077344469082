var PropTypes = require('prop-types');
var React = require('react');
var createReactClass = require('create-react-class');
var ReactDOM = require('react-dom');
var Classable = require('../mixins/classable');

var FocusRipple = createReactClass({
  displayName: 'FocusRipple',
  mixins: [Classable],

  propTypes: {
    show: PropTypes.bool
  },

  componentDidMount: function() {
    this._setRippleSize();
  },

  render: function() {
    var classes = this.getClasses('mui-focus-ripple', {
      'mui-is-shown': this.props.show
    });

    return (
      <div className={classes}>
        <div className="mui-focus-ripple-inner" />
      </div>
    );
  },

  _setRippleSize: function() {
    var el = ReactDOM.findDOMNode(this);
    var height = el.offsetHeight;
    var width = el.offsetWidth;
    var size = Math.max(height, width);

    el.style.height = size + 'px';
    el.style.top = (size / 2 * -1) + (height / 2) + 'px';
  },
});

module.exports = FocusRipple;