import * as React from 'react';
import Text from '../../../shared/Text';
import { LoginPageType } from '../../constants/types';
import { getLoginPageType } from '../../utils/login-page-type';
import { PAGE_URL_SMB } from '../../constants/urls';

const RedirectLinkSmb = () => {
  const loginPageType = getLoginPageType();

  if (loginPageType === LoginPageType.SMB || !window.__SMB_URL) {
    return null;
  }

  return (
    <p className="c42-redirect-text" data-test="redirect-smb">
      <a href={PAGE_URL_SMB}>{Text.get('redirect_to_SMB_login_text')}</a>
    </p>
  );
};

export default RedirectLinkSmb;
