var React = require('react');
var createReactClass = require('create-react-class');
var Classable = require('../mixins/classable');

var SvgIcon = createReactClass({
  displayName: 'SvgIcon',
  mixins: [Classable],

  getDefaultProps: function() {
    return {
      viewBox: "0 0 24 24"
    };
  },

  render: function() {
    var classes = this.getClasses('mui-svg-icon');

    return (
      <svg
        {...this.props}
        viewBox={this.props.viewBox}
        className={classes}>
        {this.props.children}
      </svg>
    );
  },
});

module.exports = SvgIcon;
