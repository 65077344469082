const ReactDOM = require('react-dom');

module.exports = {
  moveMenuBelow: function() {
    var node = ReactDOM.findDOMNode(this);
    var menu = node.querySelector('.mui-menu');
    if (menu) {
      menu.style.top = node.offsetHeight + 'px';
    }
  }
};
