var _uniqueId = require('lodash/uniqueId');

module.exports = {

  getDomId: function() {
    var rootNodeId = _uniqueId();
    return 'dom_id' + rootNodeId.replace(/\./g, '_');
  }

};
