import ReactTextHelper from '@c42/helpers/lib/utils/reactText';
import classNames from 'classnames';
import * as React from 'react';
import { QRCode } from 'react-qr-svg';
import Text from '../../../shared/Text';

const LINK_GA_IOS_URL = 'https://itunes.apple.com/us/app/google-authenticator/id388497605';
const LINK_GA_ANDROID_URL =
  'https://play.google.com/store/apps/details?id=com.google.android.apps.authenticator2';
const LINK_AUTH_FIREFOX_URL =
  'https://addons.mozilla.org/en-US/firefox/addon/auth-helper/?src=external-website';
const LINK_AUTH_CHROME_URL =
  'https://chrome.google.com/webstore/detail/authenticator/bhghoamapcdpbohphigoooaddinpkbai';
const LINK_AUTH_EDGE_URL =
  'https://microsoftedge.microsoft.com/addons/detail/authenticator/ocglkepbibnalbgmbachknglpdipeoio';

export const LoginSetupTotpDetails = () => (
  <div className="totp-config-step-body bordered">
    <span>
      <strong>{Text.get('login_totp_config_step1_details')}</strong>
    </span>
    <div>
      <span>{Text.get('login_totp_config_step1_app')}</span>
      <ul className="totp-config-list">
        <li>
          <a target="_blank" rel="noopener noreferrer" href={LINK_GA_IOS_URL}>
            {Text.get('login_totp_config_device_ios')}
          </a>
        </li>
        <li>
          <a target="_blank" rel="noopener noreferrer" href={LINK_GA_ANDROID_URL}>
            {Text.get('login_totp_config_device_android')}
          </a>
        </li>
      </ul>
    </div>
    <div>
      <span>{Text.get('login_totp_config_step1_browser')}</span>

      <ul className="totp-config-list">
        <li>
          <a target="_blank" rel="noopener noreferrer" href={LINK_AUTH_FIREFOX_URL}>
            {Text.get('login_totp_config_browser_firefox')}
          </a>
        </li>
        <li>
          <a target="_blank" rel="noopener noreferrer" href={LINK_AUTH_CHROME_URL}>
            {Text.get('login_totp_config_browser_chrome')}
          </a>
        </li>
        <li>
          <a target="_blank" rel="noopener noreferrer" href={LINK_AUTH_EDGE_URL}>
            {Text.get('login_totp_config_browser_edge')}
          </a>
        </li>
      </ul>
    </div>
  </div>
);

export const LoginSetupTotpAddAccountDetails = ({
  isSecretVisible,
  onShowSecretClick,
  totpSecret,
  totpKeyUri,
}) => (
  <div className="totp-config-step-body totp-account-add bordered">
    {!isSecretVisible && (
      <p>
        {Text.get('login_totp_config_step2_opt1_or')}
        <br />
        <a href="#" onClick={onShowSecretClick}>
          {Text.get('login_totp_config_step2_opt2')}
        </a>
      </p>
    )}
    {isSecretVisible && (
      <p>
        {ReactTextHelper.createElementsFromText(Text.get('login_totp_config_step2_opt1_or_enter'))}
        <textarea
          autoFocus
          rows={1}
          className="totp-input totp-secret-input"
          data-test="totp-secret"
          id="totp-secret"
          value={totpSecret || ''}
        />
      </p>
    )}
    <QRCode level="L" value={totpKeyUri} className="totp-qr-code aafix" />
  </div>
);

export const LoginStepTotpCodeInputArea = ({ isTotpInvalid, isFormDisabled, onChangeTotp }) => (
  <div className="totp-config-step-body totp-secret-verification">
    <input
      autoFocus
      className={classNames('totp-input', { errored: isTotpInvalid })}
      data-test="totp-code"
      disabled={isFormDisabled}
      id="totp-code"
      maxLength={6}
      onChange={onChangeTotp}
    />
    <div className="totp-input-feedback">
      <small className="totp-helper-text">{Text.get('login_totp_config_step3_description')}</small>
      {isTotpInvalid && (
        <p className="totp-verify-error totp-helper-text">{Text.get('login_totp_config_step3_error')}</p>
      )}
    </div>
  </div>
);
