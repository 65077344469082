import * as React from 'react';
import Text from '../../../shared/Text';
import TextField from '@c42/ui/lib/text-field';
import { sendEmptyTextIfNull } from '../../utils/input-handlers';
import { RESET_TOTP_INDEX } from '../../constants/routes';
import { Link } from 'react-router';
import { Inputs } from '../../../shared/dataTest';

interface Props {
  password: string | null;
  isDisabled: boolean;
  notification?: string | null;
  onChangePassword: React.FormEventHandler<any>;
  resetForm?: () => void;
  errorText?: string | null;
  textFieldProps?: TextField;
}

export const ResetTotpLink: React.StatelessComponent<{}> = () => (
  <div className="forgot-password-link">
    <Link data-test="reset-totp-link" to={RESET_TOTP_INDEX as any}>
      {Text.get('login_totp_code_retrieve')}
    </Link>
  </div>
);

export default class PasswordInput extends React.Component<Props, {}> {
  resetForm = (e?: React.FormEvent<any>) => {
    if (e && 'preventDefault' in e) {
      e.preventDefault();
    }
    this.props?.resetForm();
  };

  render() {
    const { errorText, isDisabled, password, onChangePassword, textFieldProps = {} } = this.props;

    const isPasswordEmpty = !password;
    const isPasswordDirty = password === '' && isPasswordEmpty;

    const passwordErrorText =
      isPasswordDirty && isPasswordEmpty ? Text.get('_validate_password_required') : '';

    return (
      <div data-test={Inputs.PASSWORD_CONTAINER}>
        <TextField
          autoComplete="off"
          className="form-control-wrapper"
          data-test="password"
          disabled={isDisabled}
          displayErrorInLabel={true}
          errorText={errorText || passwordErrorText}
          floatingLabelText={Text.get('_placeholder_password')}
          id="password"
          onBlur={sendEmptyTextIfNull.bind(null, onChangePassword, password)}
          onChange={onChangePassword}
          type="password"
          value={password || ''}
          {...textFieldProps}
        />
      </div>
    );
  }
}
