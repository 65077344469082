import { connect } from 'react-redux';
import { onSubmitSso, onChangeProvider, fetchIdentityProviders } from '../modules/sso-data';
import StoreInterface from '../store-interface';
import Sso from '../components/sso-form';

// this function takes the global store state and maps it onto properties to pass to the underlying component
const mapStateToProps = (state: StoreInterface) => {
  const ssoData = state.ssoData.toJS();
  const authData = state.authData.toJS();

  return {
    selectedProviderUid: ssoData.ssoIdentityProviderUid,
    identityProviders: ssoData.identityProviders || [],
    regKey: authData.regKey,
    username: authData.username,
    error: ssoData.ssoError,
  };
};

// connect wraps a component with a container
// the container is store aware, whereas the component just knows about the props that are given to it
export default connect(mapStateToProps, {
  onChangeProvider,
  fetchIdentityProviders,
  onClickSubmit: onSubmitSso,
})(Sso);
