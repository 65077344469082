import PropTypes from 'prop-types';
import React from 'react';
import createReactClass from 'create-react-class';
import Classable from './mixins/classable';
import FontIcon from './font-icon';
import Toggle from './toggle';
import CheckMarkIcon from './svg-icons/c42-check-mark';
import _omit from 'lodash/omit';

const Types = {
  LINK: 'LINK',
  SEPARATOR: 'SEPARATOR',
  SUBHEADER: 'SUBHEADER',
  NESTED: 'NESTED',
  CALENDAR: 'CALENDAR',
};

const MenuItem = createReactClass({
  displayName: 'MenuItem',
  mixins: [Classable],

  propTypes: {
    'data-index': PropTypes.number.isRequired,
    iconClassName: PropTypes.string,
    iconRightClassName: PropTypes.string,
    attribute: PropTypes.string,
    number: PropTypes.string,
    data: PropTypes.string,
    toggle: PropTypes.bool,
    onClick: PropTypes.func,
    onToggle: PropTypes.func,
    selected: PropTypes.bool,
    dataTest: PropTypes.string,
    disabled: PropTypes.bool
  },

  statics: {
    Types: Types
  },

  getDefaultProps: function() {
    return {
      toggle: false,
      disabled: false
    };
  },

  render: function() {
    const classes = this.getClasses('mui-menu-item', {
      'mui-is-selected': this.props.selected,
      'mui-is-disabled': this.props.disabled
    });
    let icon,
        data,
        iconRight,
        attribute,
        number,
        toggle,
        checkmark;

    if (this.props.selected && this.props.includeCheckMark) checkmark = <CheckMarkIcon />;
    if (this.props.iconClassName) icon = <FontIcon className={'mui-menu-item-icon ' + this.props.iconClassName} />;
    if (this.props.iconRightClassName) iconRight = <FontIcon className={'mui-menu-item-icon-right ' + this.props.iconRightClassName} />;
    if (this.props.data) data = <span className="mui-menu-item-data">{this.props.data}</span>;
    if (this.props.number !== undefined) number = <span className="mui-menu-item-number">{this.props.number}</span>;
    if (this.props.attribute !== undefined) attribute = <span className="mui-menu-item-attribute">{this.props.attribute}</span>;

    if (this.props.toggle) {
      const toggleProps = _omit(this.props, [
        'disabled',
        'toggle',
        'onClick',
        'onToggle',
        'children',
        'label',
        'payload',
        'text',
        'attribute',
        'number',
        'dataTest',
        'includeCheckMark'
      ]);
      toggle = <Toggle {...toggleProps} onToggle={this._handleToggle}/>;
    }

    return (
      <div
        data-test={this.props.dataTest}
        key={this.props.index}
        className={classes}
        onClick={this._handleOnClick}>

        {icon}
        {checkmark}
        {this.props.children}
        {data}
        {attribute}
        {number}
        {toggle}
        {iconRight}

      </div>
    );
  },

  _handleOnClick: function(e) {
    if (!this.props.disabled && this.props.onClick) this.props.onClick(e, this.props['data-index']);
  },

  _handleToggle: function(e, toggled) {
    if (this.props.onToggle) this.props.onToggle(e, this.props['data-index'], toggled);
  },
});

module.exports = MenuItem;
