import { connect } from 'react-redux';
import Main from '../components/main';
import { initializeApplication, toggleWorkingScreen } from '../modules/main-data';
import { goToLogin } from '../modules/routing';
import StoreInterface from '../store-interface';

// this function takes the global store state and maps it onto properties to pass to the underlying component
const mapStateToProps = (state: StoreInterface) => {
  const mainData = state.mainData.toJS();
  const authData = state.authData.toJS();

  return {
    currentStep: authData.currentStep,
    isInitialized: mainData.isInitialized,
    isValidatingToken: mainData.isValidatingToken,
    isStorageNode: authData?.serverType?.storage,
  };
};

// connect wraps a component with a container
// the container is store aware, whereas the component just knows about the props that are given to it
export default connect(mapStateToProps, {
  toggleWorkingScreen,
  initializeApplication,
  goToLogin,
})(Main);
