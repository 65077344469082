import PropTypes from 'prop-types';
import React from 'react';
import createReactClass from 'create-react-class';
import ReactDOM from 'react-dom';
import Classable from './mixins/classable';
import classnames from 'classnames';
import _omit from 'lodash/omit';
import { valueLinkDeprecation } from './utils/value-link-deprecation';

const EnhancedTextarea = createReactClass({
  displayName: 'EnhancedTextarea',
  mixins: [Classable],

  propTypes: {
    onChange: PropTypes.func,
    onHeightChange: PropTypes.func,
    textareaClassName: PropTypes.string,
    rows: PropTypes.number
  },

  getDefaultProps: function() {
    return {
      rows: 1
    };
  },

  getInitialState: function() {
    return {
      height: this.props.rows * 24
    };
  },

  componentDidMount: function() {
    this._syncHeightWithShadow();
    // call onHeightChange when the component is mounted
    // to notify the parent if the height is larger than
    // the default of 1 row
    if (this.props.onHeightChange) {
      // pass a mock event and the current height
      this.props.onHeightChange({
        preventDefault: function(){}
      }, this.state.height);
    }
  },

  render: function() {
    const {
      textareaClassName,
      defaultValue,
      rows,
      ...otherProps
    } = this.props;
    const textareaProps = _omit(otherProps, [
      'className',
      'onChange',
      'onHeightChange'
    ]);

    const classes = this.getClasses('mui-enhanced-textarea');
    const _textareaClassName = classnames('mui-enhanced-textarea-input', textareaClassName);
    const style = {
      height: this.state.height + 'px'
    };

    valueLinkDeprecation(this.props);

    return (
      <div className={classes}>
        <textarea
          ref="shadow"
          className="mui-enhanced-textarea-shadow"
          tabIndex="-1"
          rows={rows}
          defaultValue={defaultValue}
          readOnly
          value={this.props.value} />
        <textarea
          {...textareaProps}
          ref="input"
          className={_textareaClassName}
          rows={rows}
          style={style}
          onChange={this._handleChange} />
      </div>
    );
  },

  getInputNode: function() {
    return ReactDOM.findDOMNode(this.refs.input);
  },

  _syncHeightWithShadow: function(newValue, e) {
    var shadow = ReactDOM.findDOMNode(this.refs.shadow);
    var currentHeight = this.state.height;
    var newHeight;

    if (newValue !== undefined) { shadow.value = newValue; }
    newHeight = shadow.scrollHeight;

    if (currentHeight !== newHeight && newHeight > 0) {
      this.setState({ height: newHeight });
      if (this.props.onHeightChange) {
        this.props.onHeightChange(e, newHeight);
      }
    }
  },

  _handleChange: function(e) {
    this._syncHeightWithShadow(e.target.value);

    if (this.props.onChange) this.props.onChange(e, e.target.value);
  },

  componentWillReceiveProps: function(nextProps) {
    if (nextProps.value != this.props.value) {
      this._syncHeightWithShadow(nextProps.value);
    }
  },
});

module.exports = EnhancedTextarea;
