import * as Immutable from 'immutable';
import Redux from 'redux-thunk';
import Text from '../../shared/Text';
import { LOCATION_CHANGE } from 'react-router-redux';
import StoreInterface, { Reset2faData } from '../store-interface';
import { inputValueDispatcher } from '../utils/input-handlers';
import { request, POST, RequestResponse } from '../utils/request';
import { PasswordAction, UsernameAction } from '../components/inputs';
import { goToLogin } from './routing';

/////////////////////
//  Action Types   //
/////////////////////

// visibleForTesting
export const TOTP_REQUEST_RESET_UPDATE_SUCCESS = 'login/reset-totp-data/TOTP_REQUEST_RESET_UPDATE_SUCCESS';
export const TOTP_REQUEST_RESET_UPDATE_ERROR = 'login/reset-totp-data/TOTP_REQUEST_RESET_UPDATE_ERROR';
export const TOTP_RESET_START = 'login/reset-totp-data/TOTP_RESET_START';
export const TOTP_RESET_RESPONSE = 'login/reset-totp-data/TOTP_RESET_RESPONSE';
export const TOTP_RESET_ERROR = 'login/reset-totp-data/TOTP_RESET_ERROR';

/////////////////////
//     Reducer     //
/////////////////////
const initialFormValues = {
  error: null,
  username: null,
  password: undefined,
  resetToken: null,
  confirmPassword: null,
};

// this isn't the entire initialState, just the slice that this reducer cares about
// i.e., everything under state.authData
export const initialState = Immutable.fromJS(
  Object.assign(
    {
      isCreatingTotpReset: false,
      isUpdatingTotp: false,
      notificationKey: null,
    },
    initialFormValues,
  ) as Reset2faData,
);

// the reducer should not perform any async actions
export default (state = initialState, action) => {
  switch (action.type) {
    case LOCATION_CHANGE:
      return state.merge(initialFormValues);
    // Request email for reset of 2FA
    case TOTP_REQUEST_RESET_UPDATE_SUCCESS:
      return state.merge({
        isUpdatingTotp: false,
        notificationKey: 'login_totp_reset_check_email',
        error: null,
      });
    case TOTP_REQUEST_RESET_UPDATE_ERROR:
      return state.merge({
        isUpdatingTotp: false,
        notificationKey: null,
        error: Text.get('login_totp_request_reset_error'),
      });
    // Actually reset 2FA
    case TOTP_RESET_START:
      return state.merge({
        isUpdatingTotp: true,
        notificationKey: null,
        error: null,
      });
    case TOTP_RESET_RESPONSE:
      return state.merge({
        isUpdatingTotp: false,
        notificationKey: 'login_totp_reset_success',
        error: null,
      });
    case TOTP_RESET_ERROR:
      return state.merge({
        isUpdatingTotp: false,
        notificationKey: null,
        error: Text.get('login_totp_reset_error'),
      });
    // Login to send email
    case UsernameAction.CHANGE:
      return state.merge({
        username: action.username,
      });
    case PasswordAction.CHANGE:
      return state.merge({
        password: action.password,
      });
    default:
      return state;
  }
};

/////////////////////
// Action Creators //
/////////////////////

export interface RequestResetBody {
  username: string;
  password: string;
}
export interface ResetBody {
  token: string;
}

/**
 * Request a token to reset the user's 2FA via basic auth
 * @param param0 Basic auth to request reset token
 * @returns
 */
export function onSubmitRequestTotpReset({
  username,
  password,
}: RequestResetBody): Redux.ThunkAction<Promise<any>, StoreInterface, void> {
  return (dispatch) => {
    // See LegalHold `fetchLegalHolds` for example of overloading CoreClient
    return request('/api/v32/totp-auth-factor/request-remove-token', POST, {
      body: JSON.stringify({
        username,
        password,
      }),
      headers: {
        'content-type': 'application/json',
      },
    })
      .then((response) => {
        return dispatch(onRequestResetTotpSuccess());
      })
      .catch(() => {
        return dispatch(onRequestResetTotpError());
      });
  };
}

export function onRequestResetTotpSuccess() {
  return (dispatch) => {
    dispatch({
      type: UsernameAction.CHANGE,
      username: null,
    });
    dispatch({
      type: PasswordAction.CHANGE,
      password: null,
    });
    dispatch({
      type: TOTP_REQUEST_RESET_UPDATE_SUCCESS,
    });
  };
}

export function onRequestResetTotpError() {
  return (dispatch) => {
    dispatch({
      type: TOTP_REQUEST_RESET_UPDATE_ERROR,
    });
  };
}

/**
 * Use the token from the URL via "reset email" link to reset the user's 2FA config
 * @returns
 */
export function onSubmitResetTotp({ token }: { token?: string } = {}): Redux.ThunkAction<
  Promise<any>,
  StoreInterface,
  void
> {
  return (dispatch, getState) => {
    const { totpResetTokenParam } = getState().routing.toJS();
    const resetToken = token || totpResetTokenParam;

    if (!resetToken) {
      return Promise.resolve();
    }

    dispatch({ type: TOTP_RESET_START });
    return request('/api/v32/totp-auth-factor/remove-by-token', POST, {
      body: JSON.stringify({ token: resetToken }),
      headers: { 'content-type': 'application/json' },
    })
      .then((response) => {
        dispatch(onResetTotpSuccess(response));
      })
      .catch(() => {
        return dispatch(onResetTotpError());
      });
  };
}

// eslint-disable-next-line @typescript-eslint/no-unused-vars
export function onResetTotpSuccess(response: RequestResponse) {
  return (dispatch) => {
    dispatch({
      type: TOTP_RESET_RESPONSE,
    });
    dispatch(goToLogin());
  };
}

export function onResetTotpError() {
  return (dispatch) => {
    dispatch({
      type: TOTP_RESET_ERROR,
    });
  };
}

export const onChangeUsername: Redux.ThunkAction<void, StoreInterface, void> = inputValueDispatcher.bind(
  null,
  UsernameAction.CHANGE,
  'username',
);
export const onChangePassword: Redux.ThunkAction<void, StoreInterface, void> = inputValueDispatcher.bind(
  null,
  PasswordAction.CHANGE,
  'password',
);
