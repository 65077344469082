import PropTypes from 'prop-types';
import React from 'react';
import createReactClass from 'create-react-class';
import ReactDOM from 'react-dom';
import CssEvent from './utils/css-event';
import Dom from './utils/dom';
import KeyLine from './utils/key-line';
import Classable from './mixins/classable';
import ClickAwayable from './mixins/click-awayable';
import Paper from './paper';
import MenuItem from './menu-item';
import Calendar from './date-picker/calendar';
import _omit from 'lodash/omit';

/***********************
 * Nested Menu Component
 ***********************/
const NestedMenuItem = createReactClass({
  displayName: 'NestedMenuItem',
  mixins: [Classable, ClickAwayable],

  propTypes: {
    'data-index': PropTypes.number.isRequired,
    text: PropTypes.string,
    menuItems: PropTypes.array.isRequired,
    zDepth: PropTypes.number,
    onItemClick: PropTypes.func,
    onItemTap: PropTypes.func,
    openLeft: PropTypes.bool,
    style: PropTypes.object,
  },

  getInitialState: function() {
    return { open: false };
  },

  componentClickAway: function() {
    this._closeNestedMenu();
  },

  componentDidMount: function() {
    this._positionNestedMenu();
  },

  componentDidUpdate: function() {
    this._positionNestedMenu();
  },

  render: function() {
    var classes = this.getClasses('mui-nested-menu-item', {
      'mui-open': this.state.open
    });

    return (
      <div className={classes} onMouseEnter={this._openNestedMenu} onMouseLeave={this._closeNestedMenu}>
        <MenuItem data-index={this.props['data-index']} iconRightClassName="muidocs-icon-custom-arrow-drop-right" onClick={this._onParentItemClick}>
          {this.props.text}
        </MenuItem>
        <Menu
          {...this.props}
          ref="nestedMenu"
          menuItems={this.props.menuItems}
          onItemClick={this._onMenuItemClick}
          onItemTap={this._onMenuItemTap}
          hideable
          visible={this.state.open}
          zDepth={this.props.zDepth + 1}
          includeCheckMark={this.props.includeCheckMark}
        />
      </div>
    );
  },

  _positionNestedMenu: function() {
    var el = ReactDOM.findDOMNode(this),
      nestedMenu = ReactDOM.findDOMNode(this.refs.nestedMenu);

    if (this.props.openLeft) {
      nestedMenu.style.right = el.offsetWidth + 'px';
    } else {
      nestedMenu.style.left  = el.offsetWidth + 'px';
    }
  },

  _openNestedMenu: function() {
    if (!this.props.disabled) this.setState({ open: true });
  },

  _closeNestedMenu: function() {
    this.setState({ open: false });
  },

  _toggleNestedMenu: function() {
    if (!this.props.disabled) this.setState({ open: !this.state.open });
  },

  _onParentItemClick: function() {
    this._toggleNestedMenu();
  },

  _onMenuItemClick: function(e, index, menuItem) {
    if (this.props.onItemClick) this.props.onItemClick(e, index, menuItem);
    this._closeNestedMenu();
  },

  _onMenuItemTap: function(e, index, menuItem) {
    if (this.props.onItemTap) this.props.onItemTap(e, index, menuItem);
    this._closeNestedMenu();
  },
});

/****************
 * Menu Component
 ****************/
var Menu = createReactClass({
  displayName: 'Menu',
  mixins: [Classable],

  propTypes: {
    autoWidth: PropTypes.bool,
    onItemTap: PropTypes.func,
    onItemClick: PropTypes.func,
    onToggleClick: PropTypes.func,
    menuItems: PropTypes.array.isRequired,
    selectedIndex: PropTypes.number,
    hideable: PropTypes.bool,
    visible: PropTypes.bool,
    zDepth: PropTypes.number,
    includeCheckMark: PropTypes.bool,
    style: PropTypes.object,
  },

  getInitialState: function() {
    return { nestedMenuShown: false };
  },

  getDefaultProps: function() {
    return {
      autoWidth: true,
      hideable: false,
      visible: true,
      zDepth: 1
    };
  },

  componentDidMount: function() {
    var el = ReactDOM.findDOMNode(this);

    //Set the menu with
    this._setKeyWidth(el);

    //Save the initial menu height for later
    this._initialMenuHeight = el.offsetHeight + KeyLine.Desktop.GUTTER_LESS;

    //Show or Hide the menu according to visibility
    this._renderVisibility();
  },

  componentDidUpdate: function(prevProps) {
    if (this.props.visible !== prevProps.visible) this._renderVisibility();
  },

  render: function() {
    var classes = this.getClasses('mui-menu', {
      'mui-menu-hideable': this.props.hideable,
      'mui-visible': this.props.visible,
      'mui-menu-align-left': this.props.alignLeft,
      'mui-menu-align-right': !this.props.alignLeft,
    });

    return (
      <Paper ref="paperContainer" zDepth={this.props.zDepth} className={classes} style={this.props.style}>
        {this._getChildren()}
      </Paper>
    );
  },

  getCalendarDate(){
    return this.refs.calendar && this.refs.calendar.getSelectedDate();
  },

  _getChildren: function() {
    var children = [],
      menuItem,
      itemComponent,
      isSelected;

    //This array is used to keep track of all nested menu refs
    this._nestedChildren = [];

    for (var i=0; i < this.props.menuItems.length; i++) {
      menuItem = this.props.menuItems[i];
      isSelected = (i === this.props.selectedIndex) || (menuItem.selected === true);

      const itemComponentProps = _omit(menuItem, [
        'icon',
        'data',
        'attribute',
        'number',
        'toggle',
        'onClick'
      ]);

      var content;

      content = menuItem.text;

      switch (menuItem.type) {

        case MenuItem.Types.CALENDAR:
          itemComponent = (
            <Calendar key={i} {...menuItem.props} ref="calendar" />
          );
        break;

        case MenuItem.Types.SEPARATOR:
          itemComponent = (
            <div key={i} data-index={i} className="mui-separator" />
          );
        break;

        case MenuItem.Types.LINK:
          itemComponent = (
            <a key={i} data-index={i} className="mui-menu-item" href={menuItem.payload} target={menuItem.target}>{content}</a>
          );
        break;

        case MenuItem.Types.SUBHEADER:
          itemComponent = (
            <div key={i} data-index={i} className="mui-subheader">{content}</div>
          );
          break;

        case MenuItem.Types.NESTED:
          itemComponent = (
            <NestedMenuItem
              {...itemComponentProps}
              ref={i}
              key={i}
              data-index={i}
              text={content}
              menuItems={menuItem.items}
              zDepth={this.props.zDepth}
              onItemClick={this._onNestedItemClick}
              includeCheckMark={this.props.includeCheckMark}
            />
          );
          this._nestedChildren.push(i);
          break;

        default:
          itemComponent = (
            <MenuItem
              {...itemComponentProps}
              selected={isSelected}
              ref={isSelected ? 'selectedMenuItem' : null}
              key={i}
              data-index={i}
              icon={menuItem.icon}
              data={menuItem.data}
              attribute={menuItem.attribute}
              number={menuItem.number}
              toggle={menuItem.toggle}
              onClick={this._onItemClick}
              dataTest={menuItem.dataTest}
              includeCheckMark={this.props.includeCheckMark}
            >
              {content}
            </MenuItem>
          );
      }
      children.push(itemComponent);
    }

    return children;
  },

  _setKeyWidth: function(el) {
    if (el.style.width !== '' || (this.props.style && this.props.style.width))
      return;

    var menuWidth = this.props.autoWidth ?
      KeyLine.getIncrementalDim(el.offsetWidth) + 'px' :
      '100%';

    //Update the menu width
    Dom.withoutTransition(el, function() {
      el.style.width = menuWidth;
    });
  },

  _renderVisibility: function() {
    var el;

    if (this.props.hideable) {
      el = ReactDOM.findDOMNode(this);
      var innerContainer = ReactDOM.findDOMNode(this.refs.paperContainer.getInnerContainer());

      if (this.props.visible) {

        //Open the menu
        el.style.height = '';

        //Set the overflow to visible after the animation is done so
        //that other nested menus can be shown
        CssEvent.onTransitionEnd(el, function() {
          //Make sure the menu is open before setting the overflow.
          //This is to accout for fast clicks
          if (this.props.visible) {
            innerContainer.style.overflow = 'visible';
            this._scrollToSelectedIndex();
          }
        }.bind(this));

      } else {

        //Close the menu
        el.style.height = '0px';

        //Set the overflow to hidden so that animation works properly
        innerContainer.style.overflow = 'hidden';
      }
    }
  },

  _scrollToSelectedIndex(){
    let selectedMenuItem = this.refs.selectedMenuItem;

    if (Element.prototype.scrollIntoViewIfNeeded) {
      selectedMenuItem && ReactDOM.findDOMNode(selectedMenuItem).scrollIntoViewIfNeeded();
    }
  },

  _onNestedItemClick: function(e, index, menuItem) {
    if (this.props.onItemClick) this.props.onItemClick(e, index, menuItem);
  },

  _onItemClick: function(e, index) {
    if (this.props.onItemClick) this.props.onItemClick(e, index, this.props.menuItems[index]);
  },

  _onItemToggle: function(e, index, toggled) {
    if (this.props.onItemToggle) this.props.onItemToggle(e, index, this.props.menuItems[index], toggled);
  },
});

module.exports = Menu;
