import { connect } from 'react-redux';
import { onChangeUsername, submitUsername } from '../../modules/auth-data';
import Component from '../../components/login-form/step-username';
import StoreInterface from '../../store-interface';

const mapStateToProps = (state: StoreInterface) => {
  const authData = state.authData.toJS();
  const routing = state.routing.toJS();
  const resetPasswordData = state.resetPasswordData.toJS();
  const usernameParam = routing.usernameParam;
  const username = authData.username;
  const error = authData.errorFetchingLoginConfig;
  const isFormDisabled = authData.isFetchingLoginConfig;
  const notification = resetPasswordData.notification;

  return {
    error,
    usernameParam,
    username,
    isFormDisabled,
    notification,
  };
};

export default connect(mapStateToProps, {
  onChangeUsername,
  submitForm: submitUsername,
})(Component);
