import * as React from 'react';

export interface ErrorWrapperProps {
  error: string;
  dataTest?: string;
}

const ErrorWrapper: React.StatelessComponent<ErrorWrapperProps> = ({ error, dataTest }) => (
  <div className="error" data-test={dataTest}>
    {error}
  </div>
);

export default ErrorWrapper;
