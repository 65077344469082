var PropTypes = require('prop-types');
var React = require('react');
var createReactClass = require('create-react-class');
var Classable = require('./mixins/classable');
var EnhancedButton = require('./enhanced-button');

var OutlinedButton = createReactClass({
  displayName: 'OutlinedButton',
  mixins: [Classable],

  propTypes: {
    className: PropTypes.string,
    label: function(props, propName, componentName){
      if (!props.children && !props.label) {
        return new Error('Warning: Required prop `label` or `children` was not specified in `'+ componentName + '`.');
      }
    },
    primary: PropTypes.bool,
    secondary: PropTypes.bool,
    alert: PropTypes.bool
  },

  render: function() {
    var {
        label,
        primary,
        secondary,
        alert,
        ...other
      } = this.props;
    var classes = this.getClasses('mui-outlined-button', {
      'mui-is-primary': primary && !secondary && !alert,
      'mui-is-secondary': !primary && secondary && !alert,
      'mui-is-alert': !primary && !secondary && alert
    });
    var children;

    if (label) children = <span className="mui-outlined-button-label">{label}</span>;
    else children = this.props.children;

    return (
      <EnhancedButton {...other}
        disableFocusRipple
        className={classes}>
        {children}
      </EnhancedButton>
    );
  },
});

module.exports = OutlinedButton;
