import React from 'react';
import SvgIcon from './svg-icon';

class ExpandMoreIcon extends React.Component {
  static defaultProps = {
    width: 16,
    height: 16
  };

  render() {
    return (
      <SvgIcon className="c42-expand-more-icon" {...this.props}>
        <g><path d="M16.59 8.59L12 13.17 7.41 8.59 6 10l6 6 6-6z" /></g>
      </SvgIcon>
    );
  }
}

module.exports = ExpandMoreIcon;
