import { connect } from 'react-redux';
import { resetForm } from '../modules/auth-data';
import { onRequestPasswordReset, onChangeUsername } from '../modules/reset-password-data';
import ForgotPassword from '../components/forgot-password-form';
import StoreInterface from '../store-interface';

// this function takes the global store state and maps it onto properties to pass to the underlying component
const mapStateToProps = (state: StoreInterface) => {
  const resetPasswordData = state.resetPasswordData.toJS();

  return {
    username: resetPasswordData.username,
    error: resetPasswordData.error,
    isFormSubmitted: resetPasswordData.isCreatingPasswordReset,
  };
};

// connect wraps a component with a container
// the container is store aware, whereas the component just knows about the props that are given to it
export default connect(mapStateToProps, {
  onChangeUsername,
  resetForm,
  submitForm: onRequestPasswordReset,
})(ForgotPassword);
