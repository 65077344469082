import * as React from 'react';
import Text from '../../../shared/Text';
import ReactTextHelper from '@c42/helpers/lib/utils/reactText';

const LoginStepAgentLoginError = ({loginError}) => {

  return (
    <div className="step-agent-login-error">
      <p>{ReactTextHelper.createElementsFromText(Text.get('login_error_agent_login'))}</p>
      <p>{ReactTextHelper.createElementsFromText(Text.get('login_error_agent_login_instructions'))}</p>
      <p className="error-text">{Text.get('login_error_agent_login_error_code', {errorCode: loginError})}</p>
    </div>
  );
};

export default LoginStepAgentLoginError;
