import PropTypes from 'prop-types';
import React from 'react';
import createReactClass from 'create-react-class';
import ReactDOM from 'react-dom';
import Classable from './mixins/classable';
import ClickAwayable from './mixins/click-awayable';
import ExpandMoreIcon from './svg-icons/c42-expand-more';
import Paper from './paper';
import Menu from './menu';
import classnames from 'classnames';
import MenuBelowable from './mixins/c42-menu-belowable';
import _omit from 'lodash/omit';
import _clone from 'lodash/clone';

function getSelectedIndexFromProps(props){
  const {selectedIndex} = props;
  if (selectedIndex != null) {
    return selectedIndex;
  }
  return (props.placeholderLabel == null) ? 0 : -1;
}

const DropDownMenu = createReactClass({
  displayName: 'DropDownMenu',
  mixins: [Classable, ClickAwayable, MenuBelowable],

  propTypes: {
    autoWidth: PropTypes.bool,
    onChange: PropTypes.func,
    onChangeMenuVisibility: PropTypes.func,
    menuItems: PropTypes.array.isRequired,
    defaultValue: PropTypes.string,
    placeholderLabel: PropTypes.string,
    disabled: PropTypes.bool
  },

  getDefaultProps: function() {
    return {
      autoWidth: false,
      disabled: false
    };
  },

  getInitialState: function() {
    return {
      open: false,
      selectedIndex: getSelectedIndexFromProps(this.props),
    };
  },

  componentClickAway: function() {
    this.setState({ open: false });
    this.props.onChangeMenuVisibility && this.props.onChangeMenuVisibility(false);
  },

  componentDidMount: function() {
    if (this.props.autoWidth) this._setWidth();
    this.moveMenuBelow();
  },

  componentWillReceiveProps: function(nextProps) {
    if (nextProps.hasOwnProperty('selectedIndex')) {
      this.setState({selectedIndex: getSelectedIndexFromProps(nextProps)});
    }
  },

  render: function() {
    var classes = classnames('c42-drop-down-menu', 'mui-drop-down-menu', {
      'mui-open': this.state.open,
      'mui-disabled': this.props.disabled
    });

    var menuItems = this._getMenuItems();
    const propsToOmit = Object.keys(this.constructor.propTypes).concat(['selectedIndex', 'includeCheckMark']);
    const other = _omit(this.props, propsToOmit);

    return (
      <div className={classes} {...other}>
        <div className="mui-menu-control" onClick={this._onControlClick}>
          <Paper className="mui-menu-control-bg" zDepth={0} />
          <div className="mui-menu-label">
            {menuItems[this.state.selectedIndex].text}
          </div>
          <ExpandMoreIcon />
        </div>
        <Menu
          ref="menuItems"
          autoWidth={this.props.autoWidth}
          selectedIndex={this.state.selectedIndex}
          menuItems={menuItems}
          hideable
          visible={this.state.open}
          onItemClick={this._onMenuItemClick}
          includeCheckMark
        />
      </div>
    );
  },

  _getMenuItems: function() {
    var menuItems = _clone(this.props.menuItems);
    if (this.props.defaultValue) {
      menuItems.unshift({
        text: this.props.defaultValue,
        default: true
      });
    }
    if (this.props.placeholderLabel) {
      menuItems[-1] = {
        text: this.props.placeholderLabel,
        default: true,
        disabled: true
      };
    }
    return menuItems;
  },

  _setWidth: function() {
    var el = ReactDOM.findDOMNode(this),
      menuItemsDom = ReactDOM.findDOMNode(this.refs.menuItems);

    el.style.width = menuItemsDom.offsetWidth + 'px';
  },

  _onControlClick: function() {
    if (this.props.disabled) return;
    var newState = !this.state.open;
    this.moveMenuBelow();
    this.setState({ open: newState });
    this.props.onChangeMenuVisibility && this.props.onChangeMenuVisibility(newState);
  },

  _onMenuItemClick: function(e, key, payload) {
    if (this.props.onChange && this.state.selectedIndex !== key) this.props.onChange(e, key, payload);
    this.setState({
      selectedIndex: key,
      open: false
    });
    this.props.onChangeMenuVisibility && this.props.onChangeMenuVisibility(false);
  },
});

module.exports = DropDownMenu;
