import * as Immutable from 'immutable';
import CoreClient from '@cpg/core-client';
import { DEFAULT_REDIRECT_AFTER_LOGIN } from '../constants/routes';
import StoreInterface, { SsoData } from '../store-interface';
import { LOCATION_CHANGE } from 'react-router-redux';
import Redux from 'redux-thunk';
import { MATCH_ALL_BACKSLASHES } from '../constants/regexes';

/////////////////////
//  Action Types   //
/////////////////////

// visibleForTesting
export const FETCHING_IDENTITY_PROVIDERS = 'login/sso-data/FETCHING_IDENTITY_PROVIDERS';
export const IDENTITY_PROVIDERS_RESPONSE = 'login/sso-data/IDENTITY_PROVIDERS_RESPONSE';
export const IDENTITY_PROVIDERS_ERROR = 'login/sso-data/IDENTITY_PROVIDERS_ERROR';
export const CHANGE_PROVIDER = 'login/sso-data/CHANGE_PROVIDER';
export const AUTHORITY_IDENTITY_PROVIDERS_PATH = '/api/SsoLoginIdentityProvider';
export const AUTHORITY_SSO_AUTH_LOGIN_PATH = '/api/SsoAuthLogin';

/////////////////////
//     Reducer     //
/////////////////////

const initialFormValues = {
  ssoIdentityProviderUid: null,
};

// this isn't the entire initialState, just the slice that this reducer cares about
// i.e., everything under state.authData
export const initialState = Immutable.fromJS(
  Object.assign(
    {
      isFetchingIdps: false,
      identityProviders: [],
      ssoError: null,
    },
    initialFormValues,
  ) as SsoData,
);

// the reducer should not perform any async actions
export default (state = initialState, action) => {
  switch (action.type) {
    case LOCATION_CHANGE:
      return state.merge(initialFormValues);
    case FETCHING_IDENTITY_PROVIDERS:
      return state.merge({
        isFetchingIdps: true,
        ssoError: null,
      });
    case IDENTITY_PROVIDERS_RESPONSE:
      return state.merge({
        isFetchingIdps: false,
        identityProviders: action.identityProviders,
      });
    case IDENTITY_PROVIDERS_ERROR:
      return state.merge({
        isFetchingIdps: false,
        ssoError: 'sso_load_idps_error',
      });
    case CHANGE_PROVIDER:
      return state.merge({
        ssoIdentityProviderUid: action.ssoIdentityProviderUid,
      });
    default:
      return state;
  }
};

/////////////////////
// Action Creators //
/////////////////////

export function fetchIdentityProviders(
  registrationKey: string,
  username: string,
): Redux.ThunkAction<Promise<void>, StoreInterface, void> {
  return (dispatch) => {
    dispatch({ type: FETCHING_IDENTITY_PROVIDERS });

    const query = { username, registrationKey };
    const findParams = {
      query,
      options: {
        url: AUTHORITY_IDENTITY_PROVIDERS_PATH,
      },
    };

    return CoreClient.SsoIdentityProvider.find(findParams).then(
      (data) => {
        const identityProviders = data || [];
        dispatch({
          type: IDENTITY_PROVIDERS_RESPONSE,
          identityProviders,
        });
      },
      () => {
        dispatch({ type: IDENTITY_PROVIDERS_ERROR });
      },
    );
  };
}

export function onSubmitSso(ssoIdentityProviderUid: string): Redux.ThunkAction<void, StoreInterface, void> {
  return (dispatch, getState) => {
    const state: StoreInterface = getState();
    const authData = state.authData.toJS();
    const routing = state.routing.toJS();
    const ssoAuthRedirectUrl = getSsoAuthRedirectUrl(authData, routing);

    window.location.assign(
      `${AUTHORITY_SSO_AUTH_LOGIN_PATH}?identityProviderUid=${ssoIdentityProviderUid}&redirectUrl=${encodeURIComponent(
        ssoAuthRedirectUrl,)}&uuid=${routing.uuidParam || ''}`,
    );
  };
}

export function onChangeProvider(
  e: React.FormEvent<any>,
  index: number,
  option: any,
): Redux.ThunkAction<void, StoreInterface, void> {
  return (dispatch) =>
    dispatch({
      type: CHANGE_PROVIDER,
      ssoIdentityProviderUid: option.payload,
    });
}

function getSsoAuthRedirectUrl(authData, routing): string {
  let redirectPath =
    !routing.redirectParam || routing.redirectParam.indexOf('/login') === 0
      ? DEFAULT_REDIRECT_AFTER_LOGIN
      : routing.redirectParam;

  // Remove any potential backslashes from the redirectPath (PL-105140)
  redirectPath = redirectPath.replace(MATCH_ALL_BACKSLASHES, '');

  return `/login/#/login?to=${encodeURIComponent(redirectPath)}`;
}
