var PropTypes = require('prop-types');
var React = require('react');
var createReactClass = require('create-react-class');
var Classable = require('../mixins/classable');
var DateTime = require('../utils/date-time');
var DayButton = require('./day-button');
var Locale = require('../locales/en');

var CalendarMonth = createReactClass({
  displayName: 'CalendarMonth',
  mixins: [Classable],

  propTypes: {
    displayDate: PropTypes.object.isRequired,
    onDayClick: PropTypes.func,
    selectedDate: PropTypes.object.isRequired,
    weekStart: PropTypes.number,
    futureDisabled: PropTypes.bool,
  },

  getDefaultProps: function() {
    return {
      weekStart: Locale.weekStart,
    };
  },

  render: function() {
    var classes = this.getClasses('mui-date-picker-calendar-month');

    return (
      <div className={classes}>
        {this._getWeekElements()}
      </div>
    );
  },

  _getWeekElements: function() {
    var weekArray = DateTime.getWeekArray(this.props.displayDate, this.props.weekStart);

    return weekArray.map(function(week, i) {
      return (
        <div
          key={i}
          className="mui-date-picker-calendar-month-week">
          {this._getDayElements(week)}
        </div>
      );
    }, this);
  },

  _getDayElements: function(week) {
    return week.map(function(day, i) {
      var selected = DateTime.isSameDay(this.props.selectedDate, day);
      return (
        <DayButton
          key={i}
          date={day}
          futureDisabled={this.props.futureDisabled}
          onClick={this._handleDayClick}
          selected={selected} />
      );
    }, this);
  },

  _handleDayClick: function(e, date) {
    if (this.props.onDayClick) this.props.onDayClick(e, date);
  },
});

module.exports = CalendarMonth;