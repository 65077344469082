import { connect } from 'react-redux';
import Component from '../../components/login-form/wizard';
import StoreInterface from '../../store-interface';

const mapStateToProps = (state: StoreInterface) => {
  const { currentStep } = state.authData.toJS();
  return { currentStep };
};

export default connect(mapStateToProps)(Component);
