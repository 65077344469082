import PropTypes from 'prop-types';
import React from 'react';
import createReactClass from 'create-react-class';
import ReactDOM from 'react-dom';
import Classable from './mixins/classable';
import _omit from 'lodash/omit';

const TOP_PLACE    = 'top';
const RIGHT_PLACE  = 'right';
const BOTTOM_PLACE = 'bottom';
const LEFT_PLACE   = 'left';

const DARK_TYPE    = 'dark';
const SUCCESS_TYPE = 'success';
const WARNING_TYPE = 'warning';
const DANGER_TYPE  = 'danger';
const INFO_TYPE    = 'info';
const LIGHT_TYPE   = 'light';

const FLOAT_EFFECT = 'float';
const SOLID_EFFECT = 'solid';

const NONE = 'NONE';

const Tooltip = createReactClass({

  displayName: 'Tooltip',

  mixins: [Classable],

  propTypes: {
    place: PropTypes.oneOf([TOP_PLACE, RIGHT_PLACE, BOTTOM_PLACE, LEFT_PLACE]),
    type: PropTypes.oneOf([DARK_TYPE, SUCCESS_TYPE, WARNING_TYPE, DANGER_TYPE, INFO_TYPE, LIGHT_TYPE]),
    effect: PropTypes.oneOf([FLOAT_EFFECT, SOLID_EFFECT]),
    nodeDimensions: PropTypes.func
  },

  getDefaultProps(){
    return {
      place: TOP_PLACE,
      type: DARK_TYPE,
      effect: FLOAT_EFFECT,
    };
  },

  getInitialState() {
    return {
      bodyOverride: null,
      show: false,
      x: NONE,
      y: NONE,
    };
  },

  tipDimensions(){
    var node = this.isMounted() && ReactDOM.findDOMNode(this.refs.tooltip);
    return this.nodeDimensions(node);
  },

  nodeDimensions(node){
    var dimensions = node && node.getBoundingClientRect();

    return {
      top: dimensions ? dimensions.top : 0,
      left: dimensions ? dimensions.left : 0,
      width: dimensions ? dimensions.width : 0,
      height: dimensions ? dimensions.height : 0
    };
  },

  showTooltip(e, body, skipBodyUpdate) {
    if (!skipBodyUpdate) {
      // Allow component to re-render with the new content BEFORE calculating
      // new offsets.
      e.persist(); // Must call `e.persist` or some event values will be null on the next call.
      return this.setState(
        {bodyOverride: body},
        this.showTooltip.bind(this, e, body, true)
      );
    }

    var x, y, targetDimensions;
    var offset = {x: 0, y: 0};
    var { place, effect } = this.props;
    var tipDimensions = this.tipDimensions();
    var tipWidth = tipDimensions.width;
    var tipHeight = tipDimensions.height;

    if (effect === FLOAT_EFFECT) {
      x = e.clientX;
      y = e.clientY;
    } else if (effect === SOLID_EFFECT) {
      var target = e.currentTarget ? e.currentTarget : e.target;
      var {nodeDimensions} = this.props;

      if (nodeDimensions) {
        targetDimensions = nodeDimensions(target);
      } else {
        targetDimensions = this.nodeDimensions(target);
      }

      var targetTop = targetDimensions.top;
      var targetLeft = targetDimensions.left;
      var targetWidth = targetDimensions.width;
      var targetHeight = targetDimensions.height;

      if (place === TOP_PLACE) {
        x = targetLeft + (targetWidth/2);
        y = targetTop;
      } else if (place === BOTTOM_PLACE) {
        x = targetLeft + (targetWidth/2);
        y = targetTop + targetHeight;
      } else if (place === LEFT_PLACE) {
        x = targetLeft;
        y = targetTop + (targetHeight/2);
      } else if (place === RIGHT_PLACE) {
        x = targetLeft + targetWidth;
        y = targetTop + (targetHeight/2);
      }
    }

    if(place === TOP_PLACE) {
      offset.x = -(tipWidth/2); // middle width of the target
      offset.y = -(tipHeight+19); // 10px above of the target
    }
    else if(place === BOTTOM_PLACE) {
      offset.x = -(tipWidth/2); // middle width of the target
      offset.y = 20; // 10px below of the target
    }
    else if(place === LEFT_PLACE) {
      offset.x = -(tipWidth + 16); // 10px to the left of the target
      offset.y = -(tipHeight/2); // middle height of the target
    }
    else if(place === RIGHT_PLACE) {
      offset.x = 16; // 10px to the right of the target
      offset.y = -(tipHeight/2); // middle height of the target
    }

    this.setState({
      show: true,
      x: x + offset.x,
      y: y + offset.y
    });
  },

  hideTooltip() {
    this.setState({
      show: false,
      x: NONE,
      y: NONE
    });
  },

  render() {
    var {
      style,
      place,
      type,
      children,
      ...otherProps
    } = this.props;
    const spanProps = _omit(otherProps, [
      'className',
      'show',
      'touch',
      'effect',
      'nodeDimensions'
    ]);

    var {
      x,
      y,
      show,
      bodyOverride,
    } = this.state;

    var style = {
      left: x + "px",
      top: y + "px"
    };

    style = {
      ...this.props.style,
      ...style,
    };

    var tooltipClasses = this.getClasses('c42-tooltip', {
      "c42-tooltip-show": show,
      "c42-tooltip-hide": !show,
      "c42-tooltip-place-top": place === TOP_PLACE,
      "c42-tooltip-place-bottom": place === BOTTOM_PLACE,
      "c42-tooltip-place-left": place === LEFT_PLACE,
      "c42-tooltip-place-right": place === RIGHT_PLACE,
      "c42-tooltip-type-dark": type === DARK_TYPE,
      "c42-tooltip-type-success": type === SUCCESS_TYPE,
      "c42-tooltip-type-warning": type === WARNING_TYPE,
      "c42-tooltip-type-danger": type === DANGER_TYPE,
      "c42-tooltip-type-info": type === INFO_TYPE,
      "c42-tooltip-type-light": type === LIGHT_TYPE
    });

    return (
      <span
        className={tooltipClasses}
        style={style}
        ref="tooltip"
        {...spanProps}
        >
        {bodyOverride || children}
      </span>
    );
  }
});

module.exports = Tooltip;
